<template>
  <div
    class="card multisteps-form__panel p-3 border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder mb-0">{{ $t("userInfo") }}</h5>
    <p class="mb-0 text-sm">{{ $t("userBasicInfo") }}</p>
    <argon-alert
      color="danger"
      icon="ni ni-notification-70 ni-lg"
      dismissible
      v-show="is_alert"
    >
      <strong>Warning!</strong> กรุณากรอกข้อมูลให้ครบถ้วน และถูกต้อง!
    </argon-alert>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <argon-label :isRequired="true" :label="$t('FirstName')" />
          <argon-input
            id="FirstName"
            class="multisteps-form__input"
            type="text"
            placeholder="eg. Michael"
            v-model="FirstName"
            :errorMessage="firstNameError"
          />
        </div>

        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <argon-label :isRequired="true" :label="$t('lastName')" />
          <argon-input
            id="lastName"
            class="multisteps-form__input"
            type="text"
            placeholder="eg. Prior"
            v-model="lastName"
            :errorMessage="lastNameError"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <argon-label :isRequired="true" :label="$t('company')" />
          <argon-input
            id="company"
            class="multisteps-form__input"
            type="text"
            placeholder="eg. On Point"
            v-model="company"
            :errorMessage="companyError"
          />
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <argon-label :isRequired="true" :label="$t('email')" />
          <argon-input
            id="email"
            class="multisteps-form__input"
            type="email"
            placeholder="eg. user@onpoint.com"
            v-model="email"
            :errorMessage="emailError"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <argon-label :isRequired="true" :label="$t('password')" />
          <argon-input
            id="password"
            class="multisteps-form__input"
            type="password"
            placeholder="******"
            v-model="password"
            :errorMessage="passwordError"
          />
        </div>
        <div class="col-12 col-sm-6 mt-sm-0">
          <argon-label :isRequired="true" :label="$t('users.repeatPassword')" />
          <argon-input
            id="repeat-password"
            class="multisteps-form__input"
            type="password"
            placeholder="******"
            v-model="repeatPassword"
            :errorMessage="repeatPasswordError"
          />
        </div>
      </div>
      <div class="button-row d-flex mt-2">
        <argon-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          @click="nextStepAddress"
          >Next</argon-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue"
import { useForm, useField } from "vee-validate"
import { useI18n } from "vue-i18n"
//  import ValidatePlugin from './plugins/validation';
// import { setLocale } from '@vee-validate/i18n';
import { object, string, setLocale, lazy } from "yup"
import ArgonLabel from "@/components/ArgonLabel.vue"
import ArgonInput from "@/components/ArgonInput.vue"
import ArgonButton from "@/components/ArgonButton.vue"
import ArgonAlert from "@/components/ArgonAlert.vue"

export default {
  name: "UserInfo",
  components: {
    ArgonInput,
    ArgonButton,
    ArgonAlert,
    ArgonLabel,
  },
  emits: ["nextStepAddress"],
  setup(props, { emit }) {
    const { t } = useI18n({})
    console.log("activeStep==>", props.activeStep)

    function nextStepAddress() {
      saveTempData()
      console.log("next step social!")
    }

    const schema = lazy(() =>
      object({
        FirstName: string()
          .required(() => t("errors.required", { field: t("FirstName") }))
          .min(3, t("errors.min", { length: 3 }))
          .max(50, t("errors.max", { length: 50 })),
        lastName: string()
          .required(() => t("errors.required", { field: t("lastName") }))
          .min(3, t("errors.min", { length: 3 }))
          .max(50, t("errors.max", { length: 50 })),
        company: string()
          .required(() => t("errors.required", { field: t("company") }))
          .max(50, t("errors.max", { length: 50 })),
        email: string()
          .email(() => t("errors.email", { field: t("email") }))
          .required(() => t("errors.required", { field: t("email") }))
          .max(50, t("errors.max", { length: 50 })),
        password: string()
          .required(() => t("errors.required", { field: "Password" }))
          .min(6, t("errors.min", { length: 6 }))
          .max(50, t("errors.max", { length: 50 })),
        repeatPassword: string()
          .required(() => t("errors.required", { field: "Repeat Password" }))
          .matches(password.value, {
            excludeEmptyString: true,
            message: t("errors.match", { field: "Password" }),
          }),
      })
    )

    const { handleSubmit } = useForm({
      validationSchema: schema,
    })

    const { value: FirstName, errorMessage: firstNameError } = useField(
      "FirstName"
    )
    const { value: lastName, errorMessage: lastNameError } = useField(
      "lastName"
    )
    const { value: company, errorMessage: companyError } = useField("company")
    const { value: email, errorMessage: emailError } = useField("email")
    const { value: password, errorMessage: passwordError } = useField(
      "password"
    )
    const {
      value: repeatPassword,
      errorMessage: repeatPasswordError,
    } = useField("repeatPassword")

    // const router = useRouter();
    const saveTempData = handleSubmit((values) => {
      if (password.value !== repeatPassword.value) {
        alert("Passwords do not match")
        return
      }
      const user = {
        FirstName: FirstName.value,
        lastName: lastName.value,
        company: company.value,
        email: email.value,
        password: password.value,
      }
      emit("nextStepAddress", user)
    }, onInvalidSubmit)

    let is_alert = false
    function onInvalidSubmit({ values, errors, results }) {
      is_alert = true
      console.log(errors) // a map of field names and their first error message
    }

    return {
      FirstName,
      lastName,
      company,
      email,
      password,
      repeatPassword,
      firstNameError,
      lastNameError,
      companyError,
      emailError,
      passwordError,
      repeatPasswordError,
      nextStepAddress,
      is_alert,
    }
  },
}
</script>
