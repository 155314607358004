<template>
  <div
    class="p-3 bg-white card border-radius-xl">
    <h5 class="font-weight-bolder">ค้นหาแบบละเอียด:</h5>
    <div class="multisteps-form__content">
      <div class="mt-3 row">
        <div class="col-12 col-sm-6">
          <label class="text-sm">ชื่อ</label>
          <input
            class="multisteps-form__input form-control"
            type="text"
            placeholder=""
            v-model.trim="search.FirstName"
          />
        </div>
        <div class="mt-3 col-12 col-sm-6 mt-sm-0">
          <label class="text-sm">นามสกุล</label>
          <input
            class="multisteps-form__input form-control"
            type="text"
            placeholder=""
            v-model.trim="search.LastName"
          />
        </div>
      </div>
      <div class="mt-4 text-center d-flex justify-content-between col-12">
        <argon-button
          type="button"
          color="dark"
          variant="outline"
          class="mb-0"
          title="Clear"
          size="sm"
          @click="clearFilter"
          >Clear</argon-button
        >
         <argon-button
          type="button"
          color="secondary"
          variant="gradient"
          class="mb-0 mr-4"
          title="Search"
          size="sm"
          @click="searchFilter"
          >Search</argon-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Quill from "quill";
import Choices from "choices.js";
import ArgonButton from "@/components/ArgonButton.vue";
import { reactive } from 'vue'

export default {
  name: "SearchUsers",
  components: {
    ArgonButton,
  },
  emits: ["searchFilter"],
  setup(props, ctx){
    var search = reactive({
      FirstName: "",
      LastName: "",
    })
    function searchFilter() {
        ctx.emit("searchFilter", search);
    }
    function clearFilter() {
        search.FirstName = "";
        search.LastName = "";
        ctx.emit("searchFilter", search);
    }
    return {
      search,
      searchFilter,
      clearFilter,
    }
  },
  mounted() {
    if (document.getElementById("choices-category")) {
      var element = document.getElementById("choices-category");
      new Choices(element, {
        searchEnabled: false,
      });
    }

    if (document.getElementById("choices-sizes")) {
      let element = document.getElementById("choices-sizes");
      new Choices(element, {
        searchEnabled: false,
      });
    }
  },
};
</script>
