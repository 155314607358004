<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-6 my-2">
            <div class="card h-100">
              <div class="card-header d-flex">
                <h5 class="mb-0 text-capitalize text-primary">
                  Activate Products
                </h5>
                <div class="my-auto ms-auto">
                  <argon-button
                    color="warning"
                    variant="outline"
                    class="ml-auto"
                    text-align="right"
                    @click="showWaitList"
                    >รอ: {{ LotTotal }}</argon-button
                  >
                </div>
              </div>
              <div class="card-body pt-0">
                <div class="col-12">
                  <div>
                    <h6>
                      {{ $t("ScanTagBarcode") }}
                      <span class="text-danger text-lg">*</span>
                    </h6>
                  </div>
                  <div class="my-2" v-if="isShowAlertBarcode">
                    <argon-alert color="warning" icon="ni ni-bell-55 ni-lg">
                      <strong>ไม่มีข้อมูล!</strong> Barcode: {{ Barcode }}!
                    </argon-alert>
                  </div>
                  <input
                    id="Barcode"
                    type="text"
                    class="form-control form-control-lg h4"
                    maxlength="50"
                    name="Barcode"
                    :disabled="isDisabledBarcode"
                    placeholder="eg. 123456789"
                    @keyup.enter="getTagCode"
                    v-model.trim="Barcode"
                  />
                </div>

                <div class="col-12" v-show="isShowLotNumber">
                  <div>
                    <h6>
                      Lot Number
                      <span class="text-danger text-lg">*</span>
                    </h6>
                  </div>

                  <div class="">
                    <input
                      id="LotNumber"
                      type="text"
                      class="form-control form-control-lg h4"
                      maxlength="50"
                      name="LotNumber"
                      disabled
                      placeholder=""
                      v-model.trim="LotNumber"
                    />
                  </div>
                </div>
                <!-- button -->
                <div class="button-row d-flex mt-4">
                  <argon-button
                    type="button"
                    color="light"
                    variant="gradient"
                    class="js-btn-prev"
                    @click="clearData"
                    >ล้างข้อมูล</argon-button
                  >
                  <argon-button
                    type="button"
                    color="primary"
                    size="md"
                    variant="gradient"
                    class="ms-auto"
                    v-if="isShowActivateBtn"
                    @click="checkSaveActivate"
                    >บันทึก Activate</argon-button
                  >
                </div>
                <!-- Wait List  -->
                <div class="mb-0 my-4" v-if="isShowWaitList">
                  <argon-alert
                    icon="ni ni-check-bold ni-md"
                    color="warning"
                    :dismissible="false"
                  >
                    <strong>Info!</strong> รายการรอกด Activate
                    <hr class="horizontal dark m-0 my-2" />
                    <span
                      class="text-sm font-weight-bold my-auto ps-sm-2"
                      v-for="item in dataWaitList.waitList"
                      :key="item.TagCode"
                    >
                      Tag Barcode: {{ item.TagCode }}
                      <br />
                    </span>
                  </argon-alert>
                </div>
                <!-- Matched Success -->
                <div class="mb-0 my-4" v-if="isShowNotification">
                  <argon-alert
                    icon="ni ni-check-bold ni-md"
                    :dismissible="true"
                  >
                    <strong>Success!</strong> บันทึกข้อมูลสำเร็จ
                    <hr class="horizontal dark m-0 my-2" />
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      Tag Barcode: {{ BarcodeResult }}
                      <br />
                      Lot Number : {{ LotNumber }}
                      <br />
                      จำนวน QR Code: {{ TotalQrCode }}
                      <br />
                      รหัสสินค้า: {{ ProductCode }}
                      <br />
                      ชื่อสินค้า: {{ ProductName }}
                      <br />
                      ชนิดถุง: {{ BagType }}
                      <br />
                      สถานะ Tag Barcode: {{ $t(TagStatus) }}
                      <br />
                      วันที่ทำรายการ: {{ UpdatedAt }}
                    </p>
                  </argon-alert>
                </div>
                <!-- QR Codde Detail -->
                <div v-if="isShowCodeDetail">
                  <div
                    class="d-flex align-items-center justify-content-center mb-0 bg-gray-100 border-radius-lg p-2 my-4"
                  >
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      จำนวน QR Code:
                    </p>
                    <p
                      class="ms-auto text-primary h3 font-weight-bold my-auto me-3"
                    >
                      {{ TotalQrCode }}
                    </p>
                  </div>
                  <div class="mb-0 bg-gray-100 border-radius-lg p-2 my-4">
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <p class="text-sm font-weight-bold my-auto ps-sm-2">
                        สถานะ Tag Barcode:
                      </p>
                      <p
                        class="ms-auto text-primary h6 font-weight-bold my-auto me-3"
                      >
                        {{ $t(TagStatus) }}
                      </p>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <p class="text-sm font-weight-bold my-auto ps-sm-2">
                        ชนิดถุง:
                      </p>
                      <p class="ms-auto text-sm font-weight-bold my-auto me-3">
                        {{ BagType }}
                      </p>
                    </div>
                  </div>
                </div>

                <!-- Product Detail -->
                <div
                  v-if="isShowProductDetail"
                  class="mb-0 bg-gray-100 border-radius-lg p-2 my-4"
                >
                  <div class="d-sm-flex align-items-center">
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      รายละเอียดสินค้า (Product):
                    </p>
                  </div>
                  <div class="d-flex align-items-center justify-content-center">
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      รหัส:
                    </p>
                    <p class="ms-auto text-sm font-weight-bold my-auto me-3">
                      {{ ProductCode }}
                    </p>
                  </div>
                  <div class="d-flex align-items-center justify-content-center">
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      ชื่อ:
                    </p>
                    <p
                      class="ms-auto text-primary h6 font-weight-bold my-auto me-3"
                    >
                      {{ ProductName }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- section how to use -->
          <div class="col-lg-6 my-2">
            <div class="card">
              <div
                class="p-3 mx-4 text-center card-header d-flex justify-content-center"
              >
                <div
                  class="icon icon-shape icon-md shadow text-center border-radius-lg bg-gradient-success"
                >
                  <i
                    class="opacity-10 ni ni-single-copy-04"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div class="p-3 pt-0 mx-2 card-body">
                <h6 class="mb-0 text-center">วิธีการใช้งาน</h6>
                <hr class="my-3 horizontal dark" />
                <p class="h6">
                  ACTIVATE คือ การเปลี่ยนสถานะของ QR Code ภายใน Tag Barcode
                  ให้ระบบสามารถนำไปใช้ต่อในการสะสมคะแนน หรือชิงโชคต่อไป
                </p>
                <div class="">
                  <ul class="list-unstyled">
                    <li>
                      1. ผู้ใช้จะต้องนำ TAG Barcode
                      ที่บรรจุเสร็จเรียบร้อยมาสแกนอีกครั้ง
                    </li>
                    <li>2. กดปุ่ม "ล้างข้อมูล" เพื่อล้างข้อมูล Tag Barcode</li>
                    <li>
                      3. กดปุ่ม "บันทึก Activate" เพื่อบันทึกข้อมูลการ Activate
                      QR Code
                      <ul>
                        <li>
                          หลังจากกด ACTIVATE ข้อมูลของ TAG
                          จะถูกปรับเปลี่ยนเป็นพร้อมใช้งานโดยระบบ
                        </li>
                      </ul>
                    </li>
                    <li>
                      4. "รอ: xx" : หมายถึงจำนวนรายการ Tag Barcode ที่บันทึก Lot
                      Number แล้วแต่ยังไม่ได้กด Activated
                      <ul>
                        <li>
                          กดที่ปุ่ม "รอ: xx" เพื่อดูรายการที่ยังไม่ได้ Activated
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios"
import { ref, onMounted, reactive } from "vue"
import { useForm, useField } from "vee-validate"
import { useI18n } from "vue-i18n"
import { useRouter } from "vue-router"
import { object, string, setLocale, lazy } from "yup"
import Swal from "sweetalert2"
import getDateFormat from "@/includes/getDateFormat"
import ArgonLabel from "@/components/ArgonLabel.vue"

import ArgonButton from "@/components/ArgonButton.vue"
import ArgonAlert from "@/components/ArgonAlert.vue"
import ArgonBadge from "@/components/ArgonBadge.vue"
import ArgonSnackbar from "@/components/ArgonSnackbar.vue"

export default {
  name: "ActivateProduct",
  components: {
    ArgonButton,
    ArgonAlert,
    ArgonLabel,
    ArgonBadge,
    ArgonSnackbar,
  },
  data() {
    return {}
  },
  setup(props, ctx) {
    const { t } = useI18n({})
    const router = useRouter()

    var dataWaitList = reactive({
      waitList: [],
    })

    var LotNumber = ref(null)
    var ProductCode = ref(null)
    var ProductName = ref(null)
    var TagStatus = ref(null)
    var TotalQrCode = ref(null)
    var BagType = ref(null)
    var UpdatedAt = ref(null)

    //show
    var isShowCodeDetail = ref(false)
    var isShowProductDetail = ref(false)
    var isShowLotNumber = ref(false)
    var isShowActivateBtn = ref(false)
    var isShowNotification = ref(false)
    var isShowAlertBarcode = ref(false)
    var isDisabledBarcode = ref(false)
    var Barcode = ref(null)
    var BarcodeResult = ref(null)
    var tagCodeError = ref(null)
    var isShowWaitList = ref(false)
    var LotTotal = ref(null)

    const { handleSubmit } = useForm({
      validationSchema: schema,
    })

    function focusOnBarCode() {
      const el = document.getElementById("Barcode")
      el.focus()
    }

    function unFocusOnBarCode() {
      const el = document.getElementById("Barcode")
      el.blur()
    }

    onMounted(() => {
      focusOnBarCode()
      getLotStatusTotal()
    })

    const schema = lazy(() =>
      object({
        Barcode: string()
          .required(() => t("errors.required", { field: t("Barcode") }))
          .min(18, t("errors.min", { length: 18 }))
          .max(50, t("errors.max", { length: 50 })),
      })
    )

    function unAuthorized() {
      localStorage.removeItem("curAuthen")
      localStorage.removeItem("perms")
      router.push("/login")
    }

    function checkSaveActivate() {
      if (Barcode.value == null) {
        showAlertNoBarcode()
        return
      }
      if (Barcode.value == 0) {
        showAlertNoBarcode()
        return
      }
      showAlertConfirm()
    }

    function showAlertConfirm() {
      Swal.fire({
        title: "ยืนยันบันทึกข้อมูล?",
        text:
          "Lot Number คือ " +
          LotNumber.value +
          ", จำนวน QR Code มี " +
          TotalQrCode.value +
          " ดวง!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน, บันทึก Activate!",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          saveActivate()
        }
      })
    }

    const saveActivate = handleSubmit((values) => {
      const token = JSON.parse(localStorage.getItem("curAuthen")).token
      const uid = JSON.parse(localStorage.getItem("curAuthen")).uid
      const objStart = {
        TagStatus: "Activated",
        UpdatedBy: uid,
      }
      let res = axios({
        method: "put",
        url: "tagProductActivate/" + Barcode.value,
        data: objStart,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          clear()
          let rs = response.data.data
          BarcodeResult.value = rs.TagCode
          Barcode.value = rs.TagCode
          ProductName.value = rs.ProductName
          ProductCode.value = rs.ProductCode
          TagStatus.value = rs.TagStatus
          TotalQrCode.value = rs.TotalQrCode
          BagType.value = rs.BagType
          UpdatedAt.value = ""
          if (rs.UpdatedAt != null) {
            UpdatedAt.value = getDateFormat(rs.UpdatedAt)
          }
          LotNumber.value = rs.LotNumber
          isShowNotification.value = true
          isShowActivateBtn.value = false
          unFocusOnBarCode()
          return true
        })
        .catch((error) => {
          if (error.response.status === 401) {
            unAuthorized()
            return
          }
          showAlertCannotSave()
          return false // return false to stop the form submission
        })
        .then((rs) => {
          getLotStatusTotal()
          return rs // for await purpose
        })
      return res
      //save
    }, onInvalidSubmit)

    //get data
    async function getTagCode() {
      if (Barcode.value) {
        let TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
        let res = await axios({
          method: "get",
          url: "tagTransactionByTagCode/" + Barcode.value,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
          .then((response) => {
            let rs = response.data.data
            ProductCode.value = rs.ProductCode
            ProductName.value = rs.ProductName
            ProductCode.value = rs.ProductCode
            TagStatus.value = rs.TagStatus
            BagType.value = rs.BagType
            TotalQrCode.value = rs.TotalQrCode
            LotNumber.value = rs.LotNumber
            return rs
          })
          .catch((error) => {
            if (error.response.status === 401) {
              unAuthorized()
              return
            }
            Swal.fire(
              "Not Found!",
              "ไม่มีรหัส Barcode " + Barcode.value + " ในระบบ",
              ""
            )
            setTimeout(function () {
              isDisabledBarcode.value = false
              clear()
            }, 10)
            return false // pass to finish
          })
          .then((rs) => {
            isShowNotification.value = false
            isDisabledBarcode.value = true
            switch (TagStatus.value) {
              case "Mlotno":
                isShowActivateBtn.value = true
                isShowLotNumber.value = true
                isShowCodeDetail.value = true
                isShowProductDetail.value = false
                isShowAlertBarcode.value = false
                break
              default:
                isShowLotNumber.value = false
                isShowActivateBtn.value = false
                isShowCodeDetail.value = true
                isShowProductDetail.value = true
                isShowAlertBarcode.value = false
                break
            }
            return rs // for await purpose
          })
        return res
      } else {
        showAlertNoBarcode()
        isDisabledBarcode.value = false
        isDisabledLotNumber.value = true
      }
    }

    async function getLotStatusTotal() {
      let TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
      const status = "Mlotno"
      let res = await axios({
        method: "get",
        url: "totalRowsByTagStatus/" + status,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
        .then((response) => {
          LotTotal.value = response.data.totalRows
          return response.data
        })
        .catch((error) => {
          if (error.response.status === 401) {
            unAuthorized()
            return
          }
          return false // pass to finish
        })
        .then((rs) => {
          return rs // for await purpose
        })
      return res
    }

    function showWaitList() {
      if (isShowWaitList.value == false) {
        getWaitList()
      } else {
        focusOnBarCode()
      }
      isShowWaitList.value = !isShowWaitList.value
    }

    async function getWaitList() {
      let TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
      const status = "Mlotno"
      let res = await axios({
        method: "get",
        url: "tagsByTagStatus/" + status,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
        .then((response) => {
          var rs = response.data.data
          dataWaitList.waitList = rs
          return rs
        })
        .catch((error) => {
          if (error.response.status === 401) {
            unAuthorized()
            return
          }
          return false // pass to finish
        })
        .then((rs) => {
          return rs // for await purpose
        })
      return res
    }

    let isInvalidSubmit = false
    function onInvalidSubmit({ values, errors, results }) {
      isInvalidSubmit = true
      console.log(errors) // a map of field names and their first error message
    }

    function clearData() {
      clear()
      setTimeout(function () {
        focusOnBarCode()
      }, 1)
    }

    function clear() {
      Barcode.value = ""
      LotNumber.value = ""
      isShowActivateBtn.value = false
      isShowLotNumber.value = false
      isShowCodeDetail.value = false
      isShowProductDetail.value = false
      isShowAlertBarcode.value = false
      isShowNotification.value = false
      isDisabledBarcode.value = false
      isShowWaitList.value = false
      focusOnBarCode()
    }

    function showAlertNoBarcode() {
      Swal.fire("กรุณากรอกข้อมูล Barcode")
    }

    function showAlertCannotSave() {
      Swal.fire(
        "Error!",
        "ไม่สามารถบันทึกข้อมูลได้ กรุณาตรวจสอบ Barcode " +
          Barcode.value +
          " ในระบบ ว่ามีอยู่จริง!",
        ""
      )
    }

    return {
      t,
      ProductName,
      ProductCode,
      TagStatus,
      TotalQrCode,
      LotNumber,
      getTagCode,
      clear,
      checkSaveActivate,
      showAlertConfirm,
      isInvalidSubmit,
      isShowLotNumber,
      isShowActivateBtn,
      isShowCodeDetail,
      isShowProductDetail,
      isShowNotification,
      isShowAlertBarcode,
      isDisabledBarcode,
      tagCodeError,
      Barcode,
      BarcodeResult,
      BagType,
      UpdatedAt,
      clearData,
      showWaitList,
      getLotStatusTotal,
      isShowWaitList,
      dataWaitList,
      LotTotal,
    }
  },
}
</script>
