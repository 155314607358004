<template>
  <div
    class="card multisteps-form__panel p-3 border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">Socials</h5>
    <div class="multisteps-form__content">
      <div class="row mt-3">
         <div class="col-12">
          <label>Phone Number</label>
          <input
            class="multisteps-form__input form-control"
            type="text"
            placeholder=""
            v-model="phoneNumber"
          />
        </div>
        <div class="col-12">
          <label>Line Account</label>
          <input
            class="multisteps-form__input form-control"
            type="text"
            placeholder=""
            v-model="lineAccount"
          />
        </div>
        <div class="col-12 mt-3">
          <label>Facebook Account</label>
          <input
            class="multisteps-form__input form-control"
            type="text"
            placeholder="https://..."
            v-model="facebookAccount"
          />
        </div>
      </div>
      <div class="button-row d-flex mt-4">
        <argon-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="this.$parent.prevStep"
          >Prev</argon-button
        >
        <argon-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto"
          @click="nextStepSend"
          >Send</argon-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import { useForm, useField } from "vee-validate";
import { useI18n } from 'vue-i18n'
import { object, string }  from 'yup';

export default {
  name: "ContactInfo",
  components: {
    ArgonButton,
  },
  emits:['nextStepSend'],
  setup(props, { emit }){
    const { t } = useI18n({});
    console.log("activeStep==>",props.activeStep)

    function nextStepSend(){
      saveData();
      console.log("Saved!")
    }
    const schema = object().shape({
      phoneNumber: string()
        .max(50, t("errors.max", { length: 50 })),
      lineAccount: string()
        .max(100, t("errors.max", { length: 100 })),
      facebookAccount: string()
        .max(100, t("errors.max", { length: 100 })),
    });
    const { handleSubmit } = useForm({
      validationSchema: schema,
    });
    const { value: phoneNumber, errorMessage: phoneNumberError } = useField('phoneNumber');
    const { value: lineAccount, errorMessage: lineAccountError } = useField('lineAccount');
    const { value: facebookAccount, errorMessage: facebookAccountError } = useField('facebookAccount');
    const  saveData =  handleSubmit(values => {
      const contacts = {
        phoneNumber: phoneNumber.value,
        lineAccount: lineAccount.value,
        facebookAccount: facebookAccount.value,
      };
      emit("nextStepSend", contacts);
    });
    return{
      phoneNumber,
      phoneNumberError,
      lineAccount,
      lineAccountError,
      facebookAccount,
      facebookAccountError,
      nextStepSend,
    }
  },

};
</script>
