<template>
 <div class="d-flex justify-content-center">
  <li class="page-item" :class="{disabled: page === 1}">
    <a class="page-link" aria-label="Previous" @click="prevPage">
      <i class="fa fa-angle-left"></i>
    </a>
  </li>
  <li class="page-item" :class="{active: page === item}"
      :key="item"
      v-for="item in range(minPage, maxPage)">
    <a class="page-link" @click="changePage(item)">{{item}}</a>
  </li>
  <li class="page-item" :class="{disabled: page === totalPages}">
    <a class="page-link" aria-label="Next" @click="nextPage">
      <i class="fa fa-angle-right"></i>
    </a>
  </li>
</div>
</template>

<script>
import { computed, watch,ref } from 'vue'
export default {
  name: "ArgonPaginationItemTable",
  emits: ['changePage'],
  props: {
    label: {
      type: String,
      default: "",
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prev: {
      type: Boolean,
      default: false,
    },
    next: {
      type: Boolean,
      default: false,
    },
    //added
    pageCount: {
      type: Number,
      default: 0,
      description:
        "Pagination page count. This should be specified in combination with perPage"
    },
    perPage: {
      type: Number,
      default: 10,
      description:
        "Pagination per page. Should be specified with total or pageCount"
    },
    total: {
      type: Number,
      default: 0,
      description:
        "Can be specified instead of pageCount. The page count in this case will be total/perPage"
    },
    // value: {
    //   type: Number,
    //   default: 1,
    //   description: "Pagination value"
    // },
    size: {
      type: String,
      default: "",
      description: "Pagination size"
    },
    align: {
      type: String,
      default: "",
      description: "Pagination alignment (e.g center|start|end)"
    }
  },
  // data() {
  //   return {
  //     defaultPagesToDisplay: 5
  //   };
  // },
  setup(props, { emit }) {
    const defaultPagesToDisplay = 5;
    var  page = ref(1)

    

    const totalPages = computed(() => {
      if (props.pageCount > 0) return props.pageCount;
      if (props.total > 0) {
        return Math.ceil(props.total / props.perPage);
      }
      return 1;
    });

    const pagesToDisplay = computed(() => {
      if (totalPages.value > 0 && totalPages.value < defaultPagesToDisplay) {
        return totalPages.value;
      }
      return defaultPagesToDisplay;
    });

    const minPage = computed(() => {
      if (props.value >= pagesToDisplay.value) {
        const pagesToAdd = Math.floor(pagesToDisplay.value / 2);
        const newMaxPage = pagesToAdd + props.value;
        if (newMaxPage > totalPages.value) {
          return totalPages.value - pagesToDisplay.value + 1;
        }
        return props.value - pagesToAdd;
      } else {
        return 1;
      }
    });

    const maxPage = computed(() => {
      if (page.value >= pagesToDisplay.value) {
        const pagesToAdd = Math.floor(pagesToDisplay.value / 2);
        const newMaxPage = pagesToAdd + page.value;
        if (newMaxPage < totalPages.value) {
          return newMaxPage;
        } else {
          return totalPages.value;
        }
      } else {
        return pagesToDisplay.value;
      }
    });

    function range (min, max) {
      let arr = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    }

    function changePage(item) {
      page.value = item;
      if (props.disabled) return;
      emit('changePage', item);
    }

    function prevPage() {
      if (props.disabled) return;
      if (page.value > 1) {
        emit('changePage', page.value - 1);
        page.value  = page.value - 1;
      }
    }

    function nextPage() {
      if (props.disabled) return;
      if (page.value < totalPages.value) {
        emit('changePage', page.value + 1);
         page.value  = page.value + 1;
      }
    }
    return {
      totalPages,
      pagesToDisplay,
      minPage,
      maxPage,
      range,
      changePage,
      prevPage,
      nextPage,
      page,
    };
  },

};
</script>
