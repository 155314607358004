<template>
  <div>
    <label :for="id" :name="name" class="form-label"
      >{{ label }}
      <span class="text-danger text-sm" :isRequired="isRequired">*</span>
    </label>
  </div>
</template>

<script>
export default {
  name: "ArgonLabel",
  props: {
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
