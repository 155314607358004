<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-6 my-2">
            <div class="card h-100">
              <div class="card-header d-flex">
                <h5 class="mb-0 text-capitalize text-primary">
                  Delete Tag (ลบข้อมูล)
                </h5>
              </div>
              <div class="card-body pt-0">
                <div class="col-12">
                  <div>
                    <h6>
                      {{ $t("ScanTagBarcode") }}
                      <span class="text-danger text-lg">*</span>
                    </h6>
                  </div>
                  <input
                    id="Barcode"
                    type="text"
                    class="form-control form-control-lg h4"
                    name="Barcode"
                    maxlength="50"
                    :disabled="isDisabledBarcode"
                    placeholder="eg. 123456789"
                    @keyup.enter="getTagCode"
                    v-model.trim="Barcode"
                  />
                </div>

                <div class="col-12" v-show="isShowProductDetail">
                  <div>
                    <h6>ชื่อสินค้า (Product)</h6>
                  </div>

                  <div class="">
                    <input
                      type="text"
                      id="product-name"
                      class="form-control form-control-lg"
                      disabled
                      :value="ProductName"
                    />
                  </div>
                  <div
                    class="text-danger text-sm"
                    style="display: block"
                    v-if="isShowAlertProduct"
                  >
                    <strong>ไม่มีข้อมูลสินค้าที่ผูกไว้</strong>
                  </div>
                </div>
                <!-- button -->
                <div class="button-row d-flex mt-4">
                  <argon-button
                    type="button"
                    color="light"
                    variant="gradient"
                    class="js-btn-prev"
                    @click="clearData"
                    >ล้างข้อมูล</argon-button
                  >
                  <argon-button
                    type="button"
                    color="primary"
                    size="md"
                    variant="gradient"
                    class="ms-auto"
                    v-if="isShowRemoveMatchBtn"
                    :disabled="isDisabledRemoveMatchBtn"
                    @click="checkSaveRemoveMatch"
                    >ลบการจับคู่ Tag Barcode</argon-button
                  >
                </div>

                <!-- Matched Success -->
                <div class="mb-0 my-4" v-if="isShowNotification">
                  <argon-alert
                    icon="ni ni-check-bold ni-md"
                    :dismissible="true"
                  >
                    <strong>Success!</strong> {{ msgHeader }}
                    <hr class="horizontal dark m-0 my-2" />
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      Tag Barcode: {{ BarcodeResult }}
                      <br />
                      จำนวน QR Code: {{ TotalQrCode }}
                      <br />
                      รหัสสินค้า: {{ ProductCode }}
                      <br />
                      ชื่อสินค้า: {{ ProductName }}
                      <br />
                      ชนิดถุง: {{ BagType }}
                      <br />
                      สถานะ Tag Barcode: {{ $t(TagStatus) }}
                      <br />
                      วันที่ทำรายการ: {{ UpdatedAt }}
                    </p>
                  </argon-alert>
                </div>
                <!-- QR Codde Detail -->
                <div v-if="isShowCodeDetail">
                  <div
                    class="d-flex align-items-center justify-content-center mb-0 bg-gray-100 border-radius-lg p-2 my-4"
                  >
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      จำนวน QR Code:
                    </p>
                    <p
                      class="ms-auto text-primary h3 font-weight-bold my-auto me-3"
                    >
                      {{ TotalQrCode }}
                    </p>
                  </div>
                  <div class="mb-0 bg-gray-100 border-radius-lg p-2 my-4">
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <p class="text-sm font-weight-bold my-auto ps-sm-2">
                        สถานะ Tag Barcode:
                      </p>
                      <p
                        class="ms-auto text-primary h6 font-weight-bold my-auto me-3"
                      >
                        {{ $t(TagStatus) }}
                      </p>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <p class="text-sm font-weight-bold my-auto ps-sm-2">
                        ชนิดถุง:
                      </p>
                      <p class="ms-auto text-sm font-weight-bold my-auto me-3">
                        {{ BagType }}
                      </p>
                    </div>
                  </div>
                </div>

                <!-- Product Detail -->
                <div
                  v-if="isShowProductDetail"
                  class="mb-0 bg-gray-100 border-radius-lg p-2 my-4"
                >
                  <div class="d-sm-flex align-items-center">
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      รายละเอียดสินค้า (Product):
                    </p>
                  </div>
                  <div class="d-flex align-items-center justify-content-center">
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      รหัส:
                    </p>
                    <p class="ms-auto text-sm font-weight-bold my-auto me-3">
                      {{ ProductCode }}
                    </p>
                  </div>
                  <div class="d-flex align-items-center justify-content-center">
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      ชื่อ:
                    </p>
                    <p
                      class="ms-auto text-primary h6 font-weight-bold my-auto me-3"
                    >
                      {{ ProductName }}
                    </p>
                  </div>
                </div>
                <!-- delete -->
                <div class="button-row mt-4 text-center">
                  <argon-button
                    type="button"
                    color="danger"
                    size="md"
                    variant="gradient"
                    class="ms-auto"
                    :disabled="isDisabledDeleteBtn"
                    v-if="isShowDeleteBtn"
                    @click="checkSaveDelete"
                    >ลบข้อมูล Tag Barcode (ถาวร)</argon-button
                  >
                </div>
              </div>
            </div>
          </div>

          <!-- section how to use -->
          <div class="col-lg-6 my-2">
            <div class="card">
              <div
                class="p-3 mx-4 text-center card-header d-flex justify-content-center"
              >
                <div
                  class="icon icon-shape icon-md shadow text-center border-radius-lg bg-gradient-success"
                >
                  <i
                    class="opacity-10 ni ni-single-copy-04"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div class="p-3 pt-0 mx-2 card-body">
                <h6 class="mb-0 text-center">วิธีการใช้งาน</h6>
                <hr class="my-3 horizontal dark" />
                <div class="">
                  <ul class="list-unstyled">
                    <li class="h6">
                      สามารถทำรายการได้เฉพาะ Tag Barcode ที่ยังไม่เสร็จสิ้น
                      (Finish) การประกอบกับถุงนอกเท่านั้น
                    </li>
                    <li>1. ผู้ใช้งานสแกน TAG Barcode ที่ต้องการลบข้อมูล</li>
                    <ul>
                      <li>
                        หลังจากนั้น ชนิดสินค้าที่เคยผูกไว้กับ TAG Barcode
                        จะแสดงขึ้นมาในช่อง "ชื่อสินค้า (Product)"
                      </li>
                      <li>ระบบจะแสดงข้อมูลสินค้า ที่กรอบด้านล่าง</li>
                      <li>
                        สามารถ ลบการจับคู่ เฉพาะรายการที่มีสถานะ Start:
                        อยู่ระหว่างการดำเนินการ Station 2 เท่านั้น
                      </li>
                    </ul>
                    <li>
                      2. กดปุ่ม "ลบการจับคู่ Tag Barcode"
                      กรณีที่ต้องการลบข้อมูลที่ Tag Barcode ผูกกับสินค้า
                      และสถานะกลับไปเป็น Ready ให้สามารถใช้งานได้ใหม่
                    </li>
                    <li>3. กดปุ่ม "ล้างข้อมูล" เพื่อล้างข้อมูลที่กรอกไว้</li>
                    <li>
                      4. กดปุ่ม "ลบข้อมูล Tag Barcode (ถาวร)" กรณีที่ Tag
                      Barcode ขาด, เสียหาย ไม่สามารถนำกลับมาใช้ได้อีก
                      <ul>
                        <li>
                          เงื่อนไขการลบข้อมูล Tag Barcode (ถาวร) คือ ไม่มีการผูก
                          Tag Barcode กับ Qr Code แล้ว
                        </li>
                        <li>
                          ถ้าหากว่ายังมี Qr Code ที่ผูกไว้อยู่ ให้ไปที่ เมนู
                          "Retag QR Codes" เพื่อย้าย Qr Code ไปผูกกับ Tag
                          Barcode ใหม่ให้เรียบร้อยก่อน
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios"
import { ref, onMounted } from "vue"
import { useForm, useField } from "vee-validate"
import { useI18n } from "vue-i18n"
import { useRouter } from "vue-router"
import { object, string, setLocale, lazy } from "yup"
import Swal from "sweetalert2"
import getDateFormat from "@/includes/getDateFormat"
import getTodayDateFormat from "@/includes/getTodayDateFormat"

import ArgonLabel from "@/components/ArgonLabel.vue"

import ArgonButton from "@/components/ArgonButton.vue"
import ArgonAlert from "@/components/ArgonAlert.vue"

export default {
  name: "DeleteTag",
  components: {
    ArgonButton,
    ArgonAlert,
    ArgonLabel,
  },
  data() {
    return {}
  },
  setup(props, ctx) {
    const { t } = useI18n({})
    const router = useRouter()

    var ProductCode = ref(null)
    var ProductName = ref(null)
    var TagStatus = ref(null)
    var TotalQrCode = ref(null)
    var BagType = ref(null)
    var UpdatedAt = ref(null)
    var msgHeader = ref(null)

    //show
    var isShowCodeDetail = ref(false)
    var isShowProductDetail = ref(false)
    var isShowRemoveMatchBtn = ref(false)
    var isShowDeleteBtn = ref(false)
    var isDisabledDeleteBtn = ref(true)
    var isDisabledRemoveMatchBtn = ref(true)
    var isShowNotification = ref(false)
    var isShowAlertProduct = ref(false)
    var isDisabledBarcode = ref(false)
    var Barcode = ref(null)
    var BarcodeResult = ref(null)

    const { handleSubmit } = useForm({
      validationSchema: schema,
    })

    function focusOnBarCode() {
      const el = document.getElementById("Barcode")
      el.focus()
    }

    function unFocusOnBarCode() {
      const el = document.getElementById("Barcode")
      el.blur()
    }

    onMounted(() => {
      focusOnBarCode()
    })

    function unAuthorized() {
      localStorage.removeItem("curAuthen")
      localStorage.removeItem("perms")
      router.push("/login")
    }

    const schema = lazy(() =>
      object({
        Barcode: string()
          .required(() => t("errors.required", { field: t("Barcode") }))
          .min(18, t("errors.min", { length: 18 }))
          .max(50, t("errors.max", { length: 50 })),
      })
    )

    function checkSaveRemoveMatch() {
      if (Barcode.value == null) {
        showAlertNoBarcode()
        return
      }
      if (Barcode.value == 0) {
        showAlertNoBarcode()
        return
      }
      showAlertConfirmRemoveMatch()
    }

    function checkSaveDelete() {
      if (Barcode.value == null) {
        showAlertNoBarcode()
        return
      }
      if (Barcode.value == 0) {
        showAlertNoBarcode()
        return
      }
      showAlertConfirmDelete()
    }

    const saveRemoveMatch = handleSubmit((values) => {
      const token = JSON.parse(localStorage.getItem("curAuthen")).token
      const uid = JSON.parse(localStorage.getItem("curAuthen")).uid
      const objStart = {
        TagCode: Barcode.value,
        TagStatus: "Ready",
        UpdatedBy: uid,
      }
      let res = axios({
        method: "put",
        url: "removeMatchTagBarcode/" + Barcode.value,
        data: objStart,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          clear()
          let rs = response.data.data
          BarcodeResult.value = rs.TagCode
          Barcode.value = rs.TagCode
          ProductName.value = rs.ProductName
          ProductCode.value = rs.ProductCode
          TagStatus.value = rs.TagStatus
          TotalQrCode.value = rs.TotalQrCode
          BagType.value = rs.BagType
          UpdatedAt.value = ""
          if (rs.UpdatedAt != null) {
            UpdatedAt.value = getDateFormat(rs.UpdatedAt)
          }
          isShowNotification.value = true
          msgHeader.value = "ลบการจับคู่ (Match) สำเร็จ"
          return true
        })
        .catch((error) => {
          if (error.response.status === 401) {
            unAuthorized()
            return
          }
          showAlertError()
          return false // return false to stop the form submission
        })
        .then((rs) => {
          return rs // for await purpose
        })
      return res
      //save
    }, onInvalidSubmit)

    const saveDelete = handleSubmit((values) => {
      const token = JSON.parse(localStorage.getItem("curAuthen")).token
      const uid = JSON.parse(localStorage.getItem("curAuthen")).uid
      let res = axios({
        method: "delete",
        url: "deleteTagBarcode/" + Barcode.value + "/" + Number(uid),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          //imused
          if (response.status == 226) {
            showAlertCannotDelete()
            return false
          } else {
            msgHeader.value = "ลบข้อมูล Tag Barcode สำเร็จ"
            TotalQrCode.value = 0
            ProductCode.value = ""
            ProductName.value = ""
            BagType.value = ""
            TagStatus.value = "Deleted"
            UpdatedAt.value = getTodayDateFormat()

            //show
            isShowNotification.value = true
            isShowCodeDetail.value = false
            isShowProductDetail.value = false
            isShowRemoveMatchBtn.value = false
            isShowDeleteBtn.value = false
          }
          unFocusOnBarCode()
          return response
        })
        .catch((error) => {
          if (error.response.status === 401) {
            unAuthorized()
            return
          }
          showAlertError()
        })
        .then((rs) => {
          return rs // for await purpose
        })
      return res
      //save
    }, onInvalidSubmit)

    async function getTagCode() {
      if (Barcode.value) {
        let TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
        let res = await axios({
          method: "get",
          url: "tagTransactionByTagCode/" + Barcode.value,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
          .then((response) => {
            let rs = response.data.data
            ProductCode.value = rs.ProductCode
            ProductName.value = rs.ProductName
            TagStatus.value = rs.TagStatus
            TotalQrCode.value = rs.TotalQrCode
            BagType.value = rs.BagType
            return rs
          })
          .catch((error) => {
            if (error.response.status === 401) {
              unAuthorized()
              return
            }
            Swal.fire(
              "Not Found!",
              "ไม่มีรหัส Barcode " + Barcode.value + " ในระบบ",
              ""
            )
            setTimeout(function () {
              isDisabledBarcode.value = false
              clear()
            }, 10)
            return false // pass to finish
          })
          .then((rs) => {
            isShowNotification.value = false
            isDisabledBarcode.value = true
            if (rs.TotalQrCode == 0) {
              isDisabledDeleteBtn.value = false
            }
            switch (TagStatus.value) {
              case "Ready":
                isShowRemoveMatchBtn.value = true
                isShowDeleteBtn.value = true
                isShowCodeDetail.value = true
                isShowProductDetail.value = true
                isShowAlertProduct.value = false
                isDisabledRemoveMatchBtn.value = true
                break
              case "Mprostart":
                isShowRemoveMatchBtn.value = true
                isShowDeleteBtn.value = true
                isShowCodeDetail.value = true
                isShowProductDetail.value = true
                isShowAlertProduct.value = false
                isDisabledRemoveMatchBtn.value = false
                break
              default:
                isShowRemoveMatchBtn.value = false
                isShowDeleteBtn.value = false
                isShowCodeDetail.value = true
                isShowProductDetail.value = true
                isShowAlertProduct.value = false
                isDisabledRemoveMatchBtn.value = true
                break
            }
            return rs // for await purpose
          })
        return res
      } else {
        showAlertNoBarcode()
        isDisabledBarcode.value = false
      }
    }

    let isInvalidSubmit = false
    function onInvalidSubmit({ values, errors, results }) {
      isInvalidSubmit = true
    }

    function clearData() {
      clear()
      setTimeout(function () {
        focusOnBarCode()
      }, 1)
    }

    function clear() {
      isDisabledBarcode.value = false
      isShowRemoveMatchBtn.value = false
      isShowDeleteBtn.value = false
      isShowCodeDetail.value = false
      isShowProductDetail.value = false
      isShowAlertProduct.value = false
      isShowNotification.value = false
      isDisabledDeleteBtn.value = true
      isDisabledRemoveMatchBtn.value = true
      Barcode.value = ""
      focusOnBarCode()
    }

    function showAlertConfirmRemoveMatch() {
      Swal.fire({
        title: "ยืนยันลบการจับคู่ Tag Barcode?",
        text: "Barcode: " + Barcode.value,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน, บันทึกลบการจับคู่!",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          saveRemoveMatch()
        }
      })
    }

    function showAlertConfirmDelete() {
      Swal.fire({
        title: "ยืนยันลบข้อมูล?",
        text:
          "จำนวน QR Code มี " +
          TotalQrCode.value +
          " ดวง! ถ้าลบจะไม่สามารถกู้คืนได้!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน, บันทึกการลบข้อมูล!",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          saveDelete()
        }
      })
    }

    function showAlertCannotDelete() {
      Swal.fire(
        "Error!",
        "ไม่สามารถลบข้อมูลได้ ต้องไม่มีการจับคู่ Tag Barcode กับ QR Code ก่อน!",
        ""
      )
    }

    function showAlertNoBarcode() {
      Swal.fire("กรุณากรอกข้อมูล Barcode")
    }

    function showAlertError() {
      Swal.fire(
        "Error!",
        "เกิดข้อผิดพลาด ไม่สามารถทำรายการได้! กรุณาตรวจสอบ Barcode " +
          Barcode.value +
          " ในระบบ ว่ามีอยู่จริง!",
        ""
      )
    }

    return {
      t,
      ProductName,
      ProductCode,
      TagStatus,
      TotalQrCode,
      getTagCode,
      clear,
      isInvalidSubmit,
      isShowRemoveMatchBtn,
      isShowDeleteBtn,
      isDisabledDeleteBtn,
      isDisabledRemoveMatchBtn,
      isShowCodeDetail,
      isShowProductDetail,
      isShowNotification,
      isShowAlertProduct,
      isDisabledBarcode,
      Barcode,
      BarcodeResult,
      BagType,
      UpdatedAt,
      checkSaveRemoveMatch,
      checkSaveDelete,
      clearData,
      msgHeader,
    }
  },
}
</script>
