<template>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-50 pt-2 pb-9"
      :style="{ backgroundImage: 'url(' + bglogin + ')' }"
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h1 class="text-white mb-2 mt-5">Welcome to TCCC</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container"></div>
  </main>
</template>

<script>
import { ref, onMounted } from "vue"
import { useRouter } from "vue-router"
import { object, string } from "yup"
import bglogin from "@/assets/img/signin-cover-4.png"
const body = document.getElementsByTagName("body")[0]
import { mapMutations } from "vuex"

export default {
  name: "SplashScreen",
  components: {},
  data() {
    return {
      bglogin,
      file: null,
      content: null,
    }
  },
  setup() {
    var status = ref(0)
    var macaddress = ref("")
    var isAuthen = ref(false)

    var errorStatus = false
    const router = useRouter()

    const schema = object({
      username: string().required().min(4),
      password: string().required().min(6),
    })

    onMounted(() => {})

    return {}
  },
  created() {
    this.$store.state.hideConfigButton = true
    this.toggleDefaultLayout()
    body.classList.remove("bg-gray-100")
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false
    this.toggleDefaultLayout()
    body.classList.add("bg-gray-100")
  },
  methods: {
    ...mapMutations(["toggleDefaultLayout"]),
  },
}
</script>
<style>
div.mcshow {
  visibility: hidden;
}
</style>
