<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">All Users</h5>
                <p class="mb-0 text-sm">
                  ข้อมูลผู้ใช้งานระบบทั้งหมด เรียงลำดับตามชื่อผู้ใช้งาน
                </p>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <a
                    href="#!"
                    class="mx-1 mb-0 btn bg-gradient-success btn-sm"
                    @click.prevent="handleAdd"
                    >+&nbsp; New User</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-content-between align-items-center px-4 py-0 mt-3"
          >
            <div class="col-lg-2 col-sm-6">
              <select
                id="choices-page-options"
                class="form-control"
                name="choices-page-options"
              ></select>
            </div>
            <div class="col-lg-6 col-sm-6 d-flex">
              <div class="input-group">
                <span class="input-group-text text-body">
                  <i class="fas fa-search" aria-hidden="true"></i>
                </span>
                <input
                  v-model.trim="searchQuery"
                  type="text"
                  size="sm"
                  class="form-control"
                  placeholder="Search here..."
                />
              </div>
              <argon-button
                color="dark"
                variant="outline"
                size="sm"
                class="mb-0"
                type="button"
                @click.prevent="handleSearch"
              >
                <div class="d-flex">
                  <span class="ni ni-zoom-split-in ni-lg me-1"></span>
                  Search
                </div>
              </argon-button>
              <argon-button
                color="dark"
                variant="outline"
                size="sm"
                class="mb-0"
                type="button"
                @click.prevent="handleShowSearch()"
              >
                <div class="d-flex">
                  <span class="ni ni-active-40 ni-lg me-1"></span>
                  Filter
                </div>
              </argon-button>
            </div>
            <!-- end search -->
          </div>
          <!-- start search filter -->
          <div v-show="formSearch === true">
            <div class="row justify-content-between px-5 py-0 mt-3">
              <search-users @searchFilter="handleSearchFilter" />
            </div>
          </div>
          <!-- end search filter -->
          <div class="px-0 pb-0 card-body">
            <!--table-->
            <div class="table-responsive">
              <table id="users-list" class="table mb-0 align-items-center">
                <thead>
                  <tr
                    class="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
                  >
                    <th>FirstName</th>
                    <th>LastName</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in tableData.users" :key="item.ID">
                    <td>
                      <div class="px-3 py-1 d-flex">
                        {{ item.FirstName }}
                      </div>
                    </td>
                    <td>
                      <p class="text-left mb-0 text-sm">
                        {{ item.LastName }}
                      </p>
                    </td>
                    <td class="text-sm">
                      <a
                        href="javascript:;"
                        class="mx-3"
                        data-bs-toggle="tooltip"
                        data-bs-original-title="Edit"
                        @click.prevent="handleEdit(item.ID)"
                      >
                        <i class="fas fa-user-edit text-secondary"></i>
                      </a>
                      <!-- <a
                        href="javascript:;"
                        data-bs-toggle="tooltip"
                        data-bs-original-title="Delete"
                      >
                        <i class="fas fa-trash text-secondary"></i>
                      </a> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="d-flex justify-content-between px-4 py-1">
              <div>
                <div class="d-flex justify-content-center">
                  Showing {{ pagination.from }} to {{ pagination.to }} of
                  {{ pagination.total }} entries
                </div>
              </div>
              <div>
                <argon-pagination>
                  <argon-pagination-item-table
                    v-model="pagination.page"
                    :per-page="pagination.perPage"
                    :total="pagination.total"
                    @changePage="onChangePage"
                  />
                </argon-pagination>
              </div>
            </div>
            <!-- end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { ref, onMounted, reactive, toRaw } from "vue"
import ArgonButton from "@/components/ArgonButton.vue"
import ArgonPagination from "@/components/ArgonPagination.vue"
import ArgonPaginationItemTable from "@/components/ArgonPaginationItemTable.vue"
import { useRouter } from "vue-router"
import SearchUsers from "./components/SearchUsers.vue"
import Choices from "choices.js"
// import * as Choices from "choices.js";

export default {
  name: "UserTables",
  props: {},
  components: {
    ArgonButton,
    ArgonPagination,
    ArgonPaginationItemTable,
    SearchUsers,
  },
  setup(props, ctx) {
    const router = useRouter()
    var pagination = reactive({
      perPageOptions: [5, 10, 25, 50, 100],
      perPage: 5,
      page: 1,
      from: 1,
      to: 1,
      total: 1,
      pageCount: 1,
    })
    var searchQuery = ref("")
    var formSearch = ref(false)
    onMounted(() => {
      if (document.getElementById("choices-page-options")) {
        var element = document.getElementById("choices-page-options")
        setTimeout(function () {
          new Choices(element, {
            searchEnabled: false,
          })
        }, 1)
        for (var i = 0; i < pagination.perPageOptions.length; i++) {
          element.innerHTML +=
            '<option value="' +
            pagination.perPageOptions[i] +
            '">' +
            pagination.perPageOptions[i] +
            "</option>"
        }
        element.addEventListener("change", (event) => {
          onChangePageOptions(event.target.value)
        })
      }
      fetchResult()
    })

    function onChangePage(toPage) {
      pagination.page = toPage
      pagination.from = (toPage - 1) * pagination.perPage + 1
      pagination.to = pagination.from + pagination.perPage - 1
      fetchResult()
    }

    function onChangePageOptions(perPage) {
      pagination.perPage = parseInt(perPage)
      pagination.from = (pagination.page - 1) * pagination.perPage + 1
      pagination.to = pagination.from + pagination.perPage - 1
      fetchResult()
    }

    function handleSearch() {
      fetchResult()
    }

    var search = reactive({
      FirstName: "",
      LastName: "",
    })
    function handleSearchFilter(params) {
      search = params
      fetchResult()
    }

    function handleShowSearch() {
      if (formSearch.value === false) {
        formSearch.value = true
      } else {
        formSearch.value = false
      }
    }
    function handleAdd() {
      // ctx.root.$router.push("UserForms")
      router.push({ name: "UserForms" })
    }
    function handleEdit(index) {
      if (index) {
        // this.$router.push({ name: 'UserForms', params: {id:index} })
      }
    }

    var tableData = reactive({
      users: [],
    })
    async function getDataUser() {
      let TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token

      // pagination.page = toPage
      // pagination.from = (toPage - 1) * pagination.perPage + 1
      // pagination.to = pagination.from + pagination.perPage - 1
      console.log("pagination: ", pagination)

      var PageOffset = 0
      if (pagination.page > 1) {
        PageOffset = (pagination.page - 1) * pagination.perPage
        // PageOffset = pagination.page * pagination.perPage
      }
      var objSearch = {
        PageOffset: PageOffset,
        PageSize: pagination.perPage,
        SearchedData: searchQuery.value,
        FirstName: search.FirstName,
        LastName: search.LastName,
      }
      let res = await axios({
        method: "post",
        url: "usersSearch",
        data: objSearch,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
        .then((response) => {
          tableData.users = []
          var dataRes = []
          var page = response.data.paginate
          if (response.data.data) {
            response.data.data.forEach((element) => {
              dataRes = element
              dataRes.status = "Off"
              dataRes.statusType = "danger"
              if (element.IsActive === 1) {
                dataRes.status = "On"
                dataRes.statusType = "success"
              }
              tableData.users.push(dataRes)
            })
          }
          pagination.from = page.RowFrom
          pagination.to = page.RowTo
          pagination.total = page.RowTotal
          pagination.pageCount = Math.ceil(page.RowTotal / pagination.perPage)
          return true
        })
        .catch((error) => {
          // failed
          //-> prepare, notify, handle error
          console.log(error)
          return false // pass to finish
        })
        .then((rs) => {
          // do something after success or error
          console.log("rs", rs)
          return rs // for await purpose
        })
      return res
    } //end getDataUser

    async function fetchResult() {
      let success = await getDataUser()
      if (success) {
        console.log("fetched data success")
        // handle success
      } else {
        console.log("fetched data failed")
        // handle error
      }
    } //end fetchResult
    return {
      tableData,
      pagination,
      searchQuery,
      search,
      formSearch,
      handleShowSearch,
      onChangePage,
      onChangePageOptions,
      handleSearch,
      handleSearchFilter,
      handleEdit,
      handleAdd,
    }
  },
}
</script>
