<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">All Users</h5>
                <p class="mb-0 text-sm">
                  ข้อมูลผู้ใช้งานระบบทั้งหมด เรียงลำดับตามชื่อผู้ใช้งาน
                </p>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <a
                    href="./new-product"
                    class="mx-1 mb-0 btn bg-gradient-success btn-sm"
                    target="_blank"
                    >+&nbsp; New Product</a
                  >
                  <!-- <button
                    type="button"
                    class="mx-1 mb-0 btn btn-outline-success btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#import"
                  >
                    Import
                  </button> -->
                  <button
                    class="mt-1 mb-0 btn btn-outline-success btn-sm export mt-sm-0"
                    data-type="csv"
                    type="button"
                    name="button"
                  >
                    Export
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <!-- start -->
            <div class="table-responsive">
               <table id="users-list" class="table table-flush">
                 <thead class="thead-light">
                   <tr>
                     <th>FirstName</th>
                     <th>LastName</th>
                     <th>Action</th>
                   </tr>
                 </thead>
                 <tbody>
                   <tr v-for="item in usersDataTable" :key="item.FirstName">
                     <td class="text-sm"> {{ item.FirstName }}</td>
                     <td class="text-sm">{{ item.LastName }}</td>
                     <td class="text-sm">
                       <!-- <button type="button" name="btnView" id="btnView" class='btn btn-sm btn-primary pull-right notify' :data-id="item.ID"> Click me</button> -->
                      <a
                        href="javascript:;"
                        data-bs-toggle="tooltip"
                        data-bs-original-title="Preview product"
                      >
                        <i class="fas fa-eye text-secondary"></i>
                      </a>
                      <a
                        href="javascript:;"
                        class="mx-3"
                        data-bs-toggle="tooltip"
                        data-bs-original-title="Edit product"
                      >
                        <i class="fas fa-user-edit text-secondary"></i>
                      </a>
                      <a
                        href="javascript:;"
                        data-bs-toggle="tooltip"
                        data-bs-original-title="Delete product"
                      >
                        <i class="fas fa-trash text-secondary"></i>
                      </a>
                    </td>
                   </tr>

                 </tbody>
               </table>
            </div>
            <div>
              <!-- <argon-pagination/>
              <argon-pagination-item/> -->
            </div>
            <!-- end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { ref, onMounted, reactive, toRaw} from 'vue'
import { DataTable } from "simple-datatables";
// import { DataTable } from "simple-datatables-pagenumber";
// import pagination from "@/assets/js/pagination.js";
import setTooltip from "@/assets/js/tooltip.js";

// import ArgonPaginationItem from "@/components/ArgonPaginationItem.vue";
// import ArgonPagination from "@/components/ArgonPagination.vue";

export default {
  name: "UserTable",
  emits: ['datatable.totalpages'],
  props: {
    //
  },
  components: {
    // ArgonPaginationItem,
    // ArgonPagination,
    
  },
  setup(props,ctx){
    var usersDataTable = reactive([]);
    // var usersDataTable =[]
    var pagination = reactive({
      perPage: 5,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100, 500, 1000],
      from:1,
      to:10,
      total:10,
    });

    async function getAllDataUser() {
      let TOKEN = JSON.parse(localStorage.getItem('curAuthen')).token;
      let res =  await axios({
        method: 'get',
        url:'users',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ TOKEN
          }
        })
        .then(response => {
          var dataRes = []
          if (response.data.data) {
            response.data.data.forEach(element =>{
              dataRes = element
              dataRes.status = 'Off'
              dataRes.statusType = 'danger'
              if (element.IsActive===1) {
                dataRes.status = 'On'
                dataRes.statusType = 'success'
              }
              usersDataTable.push(dataRes)
            })
          }
          console.log(usersDataTable)
          let passStatus = true;
          return passStatus;
        })
        .catch((error) => {
            // failed
            //-> prepare, notify, handle error
            console.log(error)
            return false // pass to finish
        })
        .then((rs) => {
            // do something after success or error
            console.log("do something after success or error")
            return rs // for await purpose
        });
        return res;
    } //end getDataUser

    async function getDataUser() {
      let TOKEN = JSON.parse(localStorage.getItem('curAuthen')).token;
      var searchQuery = '';
      var PageOffset = 0
      if (pagination.currentPage>1) {
        PageOffset = (pagination.currentPage-1) * pagination.perPage
      }
      var objSearch = {
        PageOffset: PageOffset,
        PageSize: pagination.perPage,
        SearchedData: searchQuery
      }
      let res =  await axios({
        method: 'post',
        url:'usersSearch',
        data: objSearch,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ TOKEN
          }
        })
        .then(response => {
          // usersDataTable = reactive([]);
          // usersDataTable = []
          var dataRes = []
          var page = response.data.paginate
          console.log("response.data.data==>",response.data.data)
          console.log("page==>",page)
          if (response.data.data) {
            response.data.data.forEach(element =>{
              dataRes = element
              dataRes.status = 'Off'
              dataRes.statusType = 'danger'
              if (element.IsActive===1) {
                dataRes.status = 'On'
                dataRes.statusType = 'success'
              }
              usersDataTable.push(dataRes)
            })
          }
          console.log("usersDataTable==>",usersDataTable)
          // usersDataTable.refresh()
          let passStatus = true;
          return passStatus;
        })
        .catch((error) => {
            // failed
            //-> prepare, notify, handle error
            console.log(error)
            return false // pass to finish
        })
        .then((rs) => {
            // do something after success or error
            console.log("do something after success or error")
            return rs // for await purpose
        });
        return res;
    } //end getDataUser

    

    function prepareUsersData(){
      // Customise our labels
      var labelData = {
        // placeholder: "Search students...",
        // perPage: "Show {select} students per page",
        // noRows: "No students to display",
        //Showing 1 to 5 of 26 students (Page 1 of 6 pages)
        info: "Showing {start} to {end} of {rows} students (Page {page} of {pages} pages)",

      }; 

     const dataTableUsers = new DataTable("#users-list", {
          searchable: false,
          fixedHeight: false,
          perPage : pagination.perPage,
          totalActiveRows: 26,
          labels: labelData,
        });

        console.log("dataTableUsers==>",dataTableUsers);
        console.log("data lenght==>",dataTableUsers.data.length);
        console.log("perPage==>",dataTableUsers.options.perPage);
        console.log("currentPage==>", dataTableUsers.currentPage)
        console.log("lastPage==>", dataTableUsers.lastPage)
        console.log("totalPages==>", dataTableUsers.totalPages)
        console.log("perPageSelect==>", dataTableUsers.options.perPageSelect)

        dataTableUsers.on('datatable.perpage', function(perpage) {
           //เลือกจำนวนรายการต่อหน้า แล้วทำอะไรต่อ
            alert(perpage)
        }); 

        dataTableUsers.on('datatable.search', function(query, matched) {
            //search แล้ว ทำอะไรต่อ
            console.log("query==>",query)
            console.log("matched==>",matched)
        });

        dataTableUsers.on('datatable.page', function(page) {
            //เลือกหน้าที่เท่าไหร่ แล้วทำอะไรต่อ
            console.log("page==>",page)
            usersDataTable = reactive([]);
            pagination.currentPage = page
            getDataUser()
        });
    } //end prepareUsersData

   

    async function fetchResult() {
        let success = await getDataUser()
        if (success) {
          console.log("fetched data success")
          prepareUsersData()
            // handle success 
            // #
        } else {
          console.log("fetched data failed")
            // handle error
            // #
        }
    } //end fetchResult

    onMounted(() => {
      fetchResult()
    }) //onMounted

    return {
      usersDataTable,
    }
  },
};
</script>
