<template>
  <div class="py-2 container-fluid">
    <!-- Product -->
    <div
      class="pb-0 card-body d-flex justify-content-center align-items-center"
    >
      <div class="col-12 col-md-10 col-xl-10">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">ตั้งค่า Products</h5>
                <p class="mb-0 text-sm">
                  ข้อมูลสินค้าทั้งหมด ({{ pagination.total }} รายการ),
                  เรียงลำดับตามสถานะสินค้าเปิดใช้งาน, รหัสสินค้า
                </p>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <argon-button
                    color="primary"
                    variant="gradient"
                    class="mb-0 sm"
                    type="button"
                    v-if="isShowAddNewProduct"
                    @click.prevent="checkNewProduct"
                    >+&nbsp;เพิ่มสินค้าใหม่
                  </argon-button>
                </div>
              </div>
            </div>
            <!-- insert new products  -->
            <div
              class="py-0 col-lg-6 col-md-12 col-sm-12 card-body justify-content-center align-items-center"
              v-if="isShowNewProduct"
            >
              <div class="d-flex py-0 mt-3">
                <div>
                  <ul class="list-group">
                    <li
                      class="px-0 border-0 list-group-item list-group-item align-items-center"
                    >
                      <h6>
                        สินค้าใหม่ จำนวน
                        {{ newProductData.products.length }} รายการ
                      </h6>
                    </li>
                    <li
                      v-for="item in newProductData.products"
                      :key="item.ProductCode"
                      class="px-0 border-0 list-group-item list-group-item align-items-center"
                    >
                      <div class="px-0">
                        <label class="form-check-label">
                          <h6 class="mb-0 text-sm">
                            <span class="text-primary h5"
                              >{{ item.ProductNo }}.
                            </span>
                            <span class="px-1"></span>
                            {{ item.ProductName }}
                          </h6>

                          <span class="px-3"></span>
                          รหัส: {{ item.ProductCode }}
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="d-flex">
                <argon-button
                  type="button"
                  color="light"
                  variant="gradient"
                  class="js-btn-prev"
                  @click="cancelSaveNewProduct"
                  >ยกเลิก</argon-button
                >
                <argon-button
                  type="button"
                  color="primary"
                  size="md"
                  variant="gradient"
                  class="ms-auto"
                  v-if="isShowSaveNewProductBtn"
                  @click="saveNewProduct"
                  >บันทึกเพิ่มสินค้า</argon-button
                >
              </div>
            </div>
            <hr class="my-3 horizontal dark" />
            <!-- search  -->
            <div
              class="d-flex py-0 mt-3 justify-content-center align-items-center"
              v-if="isShowSearchProduct"
            >
              <div class="col-lg-6 col-sm-6 d-flex">
                <div class="input-group">
                  <input
                    v-model.trim="searchQuery"
                    type="text"
                    maxlength="50"
                    class="form-control form-control-sm"
                    size="120"
                    placeholder="Search here..."
                  />

                  <div>
                    <argon-button
                      color="dark"
                      variant="outline"
                      class="mb-0"
                      type="button"
                      @click.prevent="handleSearch"
                    >
                      ค้นหา
                    </argon-button>
                  </div>

                  <div>
                    <argon-button
                      color="dark"
                      variant="outline"
                      class="mb-0"
                      type="button"
                      @click.prevent="clearSearch"
                    >
                      ล้างข้อมูล
                    </argon-button>
                  </div>
                </div>
              </div>
            </div>
            <!-- end search -->
          </div>
          <div class="card-body">
            <!-- Search -->

            <h6 class="text-xs text-uppercase text-body font-weight-bolder">
              เปิดใช้งาน
            </h6>
            <!-- check  -->
            <ul class="list-group">
              <li
                v-for="item in tableData.products"
                :key="item.ProductCode"
                class="px-0 border-0 list-group-item list-group-item align-items-center"
              >
                <div class="d-flex">
                  <div class="form-check form-switch ps-0">
                    <input
                      id="action"
                      name="action"
                      class="form-check-input ms-0"
                      type="checkbox"
                      :checked="item.Active"
                      @click.prevent="handleActive(item.ID, item.IsActive)"
                    />
                  </div>
                  <label class="form-check-label p-3">
                    <h6 class="mb-0 text-sm">{{ item.ProductName }}</h6>
                    รหัส: {{ item.ProductCode }}
                  </label>
                </div>
              </li>
            </ul>
          </div>
          <!-- load more -->
          <div
            class="d-flex justify-content-center align-items-center px-4 py-0 mt-3"
          >
            <div class="">
              <argon-button
                color="dark"
                variant="outline"
                class="mb-0"
                type="button"
                :disabled="isDisabledLoadMoreBtn"
                @click.prevent="handleSearchMore"
                >Load more ...
              </argon-button>
              <div class="py-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { ref, onMounted, reactive } from "vue"
import ArgonButton from "@/components/ArgonButton.vue"
import ArgonPagination from "@/components/ArgonPagination.vue"
import ArgonPaginationItemTable from "@/components/ArgonPaginationItemTable.vue"
import ArgonSwitch from "@/components/ArgonSwitch.vue"
import { useRouter } from "vue-router"
import Swal from "sweetalert2"
// import TcccProductData from "@/assets/files/TCCC-SKU-TEST.json"

export default {
  name: "ProductTables",
  props: {},
  components: {
    ArgonButton,
    ArgonPagination,
    ArgonPaginationItemTable,
    ArgonSwitch,
  },
  setup(props, ctx) {
    const router = useRouter()
    var pagination = reactive({
      perPageOptions: [5, 10, 25, 50, 100],
      perPage: 10,
      page: 1,
      from: 1,
      to: 1,
      total: 1,
      pageCount: 1,
    })
    var searchQuery = ref("")
    var isDisabledLoadMoreBtn = ref(false)
    var apiProductData = reactive([])
    var newProductData = reactive({
      products: [],
    })
    var isShowAddNewProduct = ref(true)
    var isShowSearchProduct = ref(true)
    var isShowNewProduct = ref(false)
    var newproducts = []
    var isShowSaveNewProductBtn = ref(false)

    onMounted(() => {
      fetchResult()
    })

    function unAuthorized() {
      localStorage.removeItem("curAuthen")
      localStorage.removeItem("perms")
      router.push("/login")
    }

    function handleSearch() {
      fetchResult()
    }

    function handleSearchMore() {
      pagination.perPage = pagination.perPage + 5
      fetchResult()
      // pagination.page = pagination.page + 1
    }

    function clearSearch() {
      searchQuery.value = ""
      fetchResult()
    }

    function handleActive(id, active) {
      const token = JSON.parse(localStorage.getItem("curAuthen")).token
      const uid = JSON.parse(localStorage.getItem("curAuthen")).uid
      if (active == 1) {
        active = 2
      } else {
        active = 1
      }
      const objActive = {
        IsActive: active,
        UpdatedBy: uid,
      }
      let res = axios({
        method: "put",
        url: "products-active/" + id,
        data: objActive,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          if (response.status == 200) {
            Swal.fire(
              "Success!",
              "บันทึกข้อมูลสำเร็จ! สินค้า" +
                response.data.data.ProductName +
                "",
              ""
            )
            setTimeout(function () {
              fetchResult()
            }, 10)
          } else {
            Swal.fire("Error!", "เกิดข้อผิดพลาดในการบันทึกข้อมูล", "")
          }
          return
        })
        .catch((error) => {
          if (error.response.status === 401) {
            unAuthorized()
            return
          }
          Swal.fire("Error!", "เกิดข้อผิดพลาดในการบันทึกข้อมูล", "")
          return false
        })
      return res
    }

    function getAllDataProduct() {
      let TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
      let res = axios({
        method: "get",
        url: "products",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
        .then((response) => {
          var dataRes = []
          newproducts = []
          if (response.data.data) {
            response.data.data.forEach((element) => {
              dataRes = element
              dataRes.Active = false
              newproducts.push(dataRes)
            })
          }
          return true
        })
        .catch((error) => {
          if (error.response.status === 401) {
            unAuthorized()
            return
          }
          return false // pass to finish
        })
        .then((rs) => {
          return rs // for await purpose
        })
      return res
    } //end getDataProduct

    function checkNewProduct() {
      var r = getAllDataProduct()
      const promise1 = Promise.resolve(r)
      promise1.then((value) => {
        if (r) {
          getApiDataProduct()
        }
      })
    }

    var objInsert = []
    async function getApiDataProduct() {
      //get api data
      let url = ""
      const customAxios = axios.create({
        baseURL: "http://192.168.20.9:10042/",
        timeout: 5000,
      })
      var res = await customAxios({
        method: "get",
        url: "web/services/PRODUCT",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          apiProductData = response.data.data
          return rs
        })
        .catch((error) => {
          console.log(error.response)
          return false // pass to finish
        })

      // ถ้าไม่มีข้อมูล เรียกจาก file แทน
      var checkNewProduct = true
      if (apiProductData.length == 0) {
        // apiProductData = TcccProductData
        console.log("No new product!")
        checkNewProduct = false
      }

      newProductData.products = []
      var i = 0
      const uid = JSON.parse(localStorage.getItem("curAuthen")).uid
      if (checkNewProduct == true) {
        let allData = apiProductData.PRODUCT_GETALL_R
        if (allData.length > 0) {
          allData.forEach((element) => {
            var dataRes = []
            var result = findProduct(element["MNMFG#"])
            if (!result) {
              i++
              dataRes.ProductCode = element["MNMFG#"]
              dataRes.ProductName = element.MNDESC
              dataRes.ProductNo = i
              dataRes.UpdatedBy = uid
              newProductData.products.push(dataRes)
              objInsert.push({
                ProductCode: element["MNMFG#"],
                ProductName: element.MNDESC,
                UpdatedBy: uid,
              })
            }
          })
        }
      }
      //check if > 0 show button
      if (newProductData.products.length > 0) {
        isShowSaveNewProductBtn.value = true
      }
      isShowAddNewProduct.value = false
      isShowSearchProduct.value = false
      isShowNewProduct.value = true
      return
    }

    function findProduct(code) {
      const result = newproducts.find((element) => element.ProductCode === code)
      if (result) {
        return true
      } else {
        return false
      }
    }

    function cancelSaveNewProduct() {
      isShowAddNewProduct.value = true
      isShowSearchProduct.value = true
      isShowNewProduct.value = false
      isShowSaveNewProductBtn.value = false
    }

    async function saveNewProduct() {
      let TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
      let res = await axios({
        method: "post",
        url: "products-new",
        data: objInsert,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
        .then((response) => {
          isShowAddNewProduct.value = true
          isShowSearchProduct.value = true
          isShowNewProduct.value = false
          if (response.status == 201) {
            Swal.fire(
              "Success!",
              "บันทึกเพิ่มข้อมูลสินค้าใหม่สำเร็จ! จำนวน " +
                newProductData.products.length +
                " รายการ",
              ""
            )
          } else {
            Swal.fire("Error!", "เกิดข้อผิดพลาดในการบันทึกข้อมูล", "")
          }
          isShowSaveNewProductBtn.value = false
          setTimeout(function () {
            newProductData.products = []
            objInsert = []
            isShowSaveNewProductBtn.value = false
            fetchResult()
          }, 10)

          return true
        })
        .catch((error) => {
          if (error.response.status === 401) {
            unAuthorized()
            return
          }
          Swal.fire("Error!", "เกิดข้อผิดพลาดในการบันทึกข้อมูล", "")
          return false // pass to finish
        })
        .then((rs) => {
          return rs // for await purpose
        })
      return res
    }

    var tableData = reactive({
      products: [],
    })
    async function getDataProduct() {
      let TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
      var PageOffset = 0
      if (pagination.page > 1) {
        PageOffset = pagination.page * pagination.perPage
      }
      if (PageOffset > pagination.total) {
        PageOffset = pagination.total
      }
      let Search = searchQuery.value
      if (Search == "") {
        Search = "null"
      }
      let res = await axios({
        method: "get",
        url:
          "productsPerPage/" +
          Search +
          "/" +
          pagination.perPage +
          "/" +
          PageOffset,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
        .then((response) => {
          var dataRes = []
          tableData.products = []
          var page = response.data.paginate
          if (response.data.data) {
            response.data.data.forEach((element) => {
              dataRes = element
              dataRes.Active = false
              dataRes.ActiveLabel = "ปิด"
              if (element.IsActive === 1) {
                dataRes.ActiveLabel = "เปิด"
                dataRes.Active = true
              }
              tableData.products.push(dataRes)
            })
          }
          pagination.from = page.RowFrom
          pagination.to = page.RowTo
          pagination.total = page.RowTotal
          pagination.pageCount = Math.ceil(page.RowTotal / pagination.perPage)
          isDisabledLoadMoreBtn.value = false
          let currentDataNumber = tableData.products.length
          if (
            currentDataNumber == pagination.total ||
            currentDataNumber > pagination.total ||
            pagination.total == 0 ||
            pagination.total == null
          ) {
            isDisabledLoadMoreBtn.value = true
          }
          return true
        })
        .catch((error) => {
          return false // pass to finish
        })
        .then((rs) => {
          return rs // for await purpose
        })
      return res
    } //end getDataProduct

    async function fetchResult() {
      let success = await getDataProduct()
      if (success) {
        // console.log("fetched data success")
        // handle success
      } else {
        // console.log("fetched data failed")
        // handle error
      }
    } //end fetchResult
    return {
      tableData,
      pagination,
      searchQuery,
      isDisabledLoadMoreBtn,
      newProductData,
      isShowAddNewProduct,
      isShowSearchProduct,
      isShowNewProduct,
      isShowSaveNewProductBtn,
      handleSearch,
      handleActive,
      handleSearchMore,
      clearSearch,
      cancelSaveNewProduct,
      saveNewProduct,
      checkNewProduct,
    }
  },
}
</script>
