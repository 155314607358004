<template>
  <div
    class="card multisteps-form__panel p-3 border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">{{ $t("users.address") }}</h5>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col">
          <label>{{ $t("users.address_1") }}</label>
          <argon-input
            id="address_1"
            class="multisteps-form__input"
            type="text"
            placeholder="eg. 111 ม.1 "
            v-model="address_1"
            :errorMessage=address_1Error
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label>{{ $t("users.address_2") }}</label>
          <argon-input
            id="address_2"
            class="multisteps-form__input"
            type="text"
            placeholder="eg. 221 ม.2 "
            v-model="address_2"
            :errorMessage=address_2Error
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <label>{{ $t("users.city") }}</label>
          <argon-input
            id="city"
            class="multisteps-form__input"
            type="text"
            placeholder="eg. เมือง"
            v-model="city"
            :errorMessage=cityError
          />
        </div>
        <div class="col-6 col-sm-3 mt-3 mt-sm-0">
          <label>{{ $t("users.province") }}</label>
          <select
            id="province"
            class="multisteps-form__select form-control"
            name="province"
            v-model="province"
          >
            <option selected="selected">...</option>
            <option value="1">State 1</option>
            <option value="2">State 2</option>
          </select>
        </div>
        <div class="col-6 col-sm-3 mt-3 mt-sm-0">
          <label>{{ $t("users.zipcode") }}</label>
          <argon-input
            id="zipcode"
            class="multisteps-form__input"
            type="text"
            placeholder=""
            v-model="zipcode"
            :errorMessage=zipcodeError
          />
        </div>
      </div>
      <div class="button-row d-flex mt-2">
        <argon-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="this.$parent.prevStep"
          >Prev</argon-button
        >
        <argon-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto js-btn-next"
          @click="nextStepContact"
          >Next</argon-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import Choices from "choices.js";
import { useI18n } from 'vue-i18n'
import { useForm, useField } from "vee-validate";
import { object, string, number  }  from 'yup';

export default {
  name: "AddressComponent",
  components: {
    ArgonInput,
    ArgonButton,
  },
  emits:['nextStepContact'],
  setup(props, { emit }){
    const { t } = useI18n({});
    console.log("activeStep==>",props.activeStep)
    function nextStepContact(){
      saveTempData();
      console.log("next step social!")
    }
    //validate
    const schema = object().shape({
      address_1: string()
        .max(100, t("errors.max", { length: 100 })),
      address_2: string()
        .max(100, t("errors.max", { length: 100 })),
      city: string()
        .max(50, t("errors.max", { length: 50 })),
      zipcode: number(t('errors.numeric'))
        .max(10, t("errors.max", { length: 10 })),
    });
    const { handleSubmit } = useForm({
      validationSchema: schema,
    });
    const { value: address_1, errorMessage: address_1Error } = useField('address_1');
    const { value: address_2, errorMessage: address_2Error } = useField('address_2');
    const { value: city, errorMessage: cityError } = useField('city');
    const { value: province, errorMessage: provinceError } = useField('province');
    const { value: zipcode, errorMessage: zipcodeError } = useField('zipcode');

    const saveTempData = handleSubmit(values => {
      const address = {
        address_1: address_1.value,
        address_2: address_2.value,
        city: city.value,
        province: province.value,
        zipcode: zipcode.value,
      };
      emit("nextStepContact", address);
    });
    return {
      nextStepContact,
      address_1Error,
      address_2Error,
      cityError,
      provinceError,
      zipcodeError,
      address_1,
      address_2,
      city,
      province,
      zipcode,
    };
  },
  mounted() {
    if (document.getElementById("choices-state")) {
      var element = document.getElementById("choices-state");
      new Choices(element, {
        searchEnabled: false,
      });
    }
  },
};
</script>

<style></style>
