import { DateTime } from "luxon"
export default function getTodayDateFormat() {
  //ISO => dd/mm/yyyy HH:mm (พ.ศ.)
  var today = ""
  const datenow = DateTime.now()
  const dateISO = datenow.toISO()
  if (dateISO) {
    today = DateTime.fromISO(dateISO).toFormat("dd/MM/yyyy HH:mm")
    var dt = today.split(" ")
    // var t = dt[1].split(":")
    var d = dt[0].split("/")
    var year = parseInt(d[2]) + 543
    today = d[0] + "/" + d[1] + "/" + year + "  เวลา " + dt[1] + " น."
  }
  return today
}
