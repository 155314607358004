<template>
    <router-link class="nav-link" :to="to">
      <div
        class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center"
        >
        <slot name="icon"></slot>
     </div>
       <span
        class="nav-link-text"
        :class="this.$store.state.isRTL ? ' me-1' : 'ms-1'"
        >{{ navText }}</span>
    </router-link>
</template>
<script>
export default {
  name: "SidenavNotCollapse",
  props: {
    to: {
      type: [Object, String],
      required: true,
    },
    navText: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      isExpanded: false
    };
  }
};
</script>
