import { createI18n } from 'vue-i18n'

// Vue.use(VueI18n)
// import all the language we have been define
import en from './en'
import th from './th'
// const i18n = createI18n({
//   // ...
// })
export default new createI18n({
  // locale: 'TH', // set default language
  locale: process.env.VUE_APP_I18N_LOCALE || 'th', 
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'th',
  messages:{
    en,
    th,
  }, // feed all the messages with the tall language
  numberFormats:{
    th: {
      currency: { style: 'currency', currency: 'THB' },
    },
    en:{
      currency: { style: 'currency', currency: 'USD' },
    }
  }
})
