<template>
  <div class="form-group">
    <div :class="hasIcon(icon)">
      <span v-if="iconDir === 'left'" class="input-group-text">
        <i :class="getIcon(icon)"></i>
      </span>
      <input
        :id="id"
        :type="type"
        class="form-control"
        :class="getClasses(size, success, errorMessage)"
        :name="name"
        :value="modelValue"
        :placeholder="placeholder"
        :isRequired="isRequired"
        @input="$emit('update:modelValue', $event.target.value)"
        :errorMessage="errorMessage"
        :autocomplete="autocomplete"
      />
      <!-- :class="getClasses(size, success, error, errorMessage)" -->
      <span v-if="iconDir === 'right'" class="input-group-text">
        <i :class="getIcon(icon)"></i>
      </span>
    </div>

    <div
      class="text-danger text-sm"
      style="display: block"
      v-show="errorMessage"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ArgonInput",
  props: {
    size: {
      type: String,
      default: "default",
    },
    success: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    iconDir: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: "",
    },
    errorMessage: {
      type: String,
      default: "",
    },
    autocomplete: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue"],
  methods: {
    getClasses: (size, success, errorMessage) => {
      // getClasses: (size, success, error, errorMessage) => {
      let error = false
      let sizeValue, isValidValue
      sizeValue = size ? `form-control-${size}` : null
      //Noke added this line to fix the issue with the input not being valid when it is required.
      if (
        errorMessage !== null &&
        errorMessage !== undefined &&
        errorMessage !== ""
      ) {
        error = true
        // error = false
      }

      if (error) {
        isValidValue = "is-invalid"
      } else if (success) {
        isValidValue = "is-valid"
      } else {
        isValidValue = ""
      }

      return `${sizeValue} ${isValidValue}`
    },
    getIcon: (icon) => (icon ? icon : null),
    hasIcon: (icon) => (icon ? "input-group" : null),
  },
}
</script>
