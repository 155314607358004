<template>
  <div
    id="sidenav-collapse-main"
    class="collapse navbar-collapse w-auto h-auto h-100"
  >
    <ul class="navbar-nav">
      <!-- start here -->
      <!-- start menus -->
      <li class="nav-item" v-for="menu in menus" :key="menu.ID">
        <sidenav-not-collapse
          :collapse="false"
          :nav-text="menu.AppNotes"
          :to="{ name: menu.AppLabel }"
          :class="getRoute() === menu.AppModel ? 'active' : ''"
        >
          <template #icon>
            <i :class="menu.AppIcon"></i>
          </template>
        </sidenav-not-collapse>
      </li>

      <hr class="mt-2 horizontal dark" />

      <li class="nav-item">
        <sidenav-collapse
          nav-text="Logout"
          :collapse="false"
          url="#"
          :aria-controls="''"
          collapse-ref=""
          @click.prevent="Logout"
        >
          <template #icon>
            <i class="ni ni-button-power text-warning text-sm opacity-10"></i>
          </template>
        </sidenav-collapse>
      </li>
      <!-- end menus -->
    </ul>
  </div>
</template>
<script>
import { useRouter } from "vue-router"
// import SidenavItem from "./SidenavItem.vue"
import SidenavCollapse from "./SidenavCollapse.vue"
import SidenavCard from "./SidenavCard.vue"
import SidenavCollapseItem from "./SidenavCollapseItem.vue"
import SidenavNotCollapse from "./SidenavNotCollapse.vue"

export default {
  name: "SidenavList",
  components: {
    // SidenavItem,
    SidenavCollapse,
    SidenavCard,
    SidenavCollapseItem,
    SidenavNotCollapse,
  },
  setup() {
    const router = useRouter()
    let menus = JSON.parse(localStorage.getItem("perms"))
    function Logout() {
      localStorage.removeItem("curAuthen")
      localStorage.removeItem("perms")
      router.push("/login")
    }
    // function isTokenRemove() {
    //   alert("isTokenRemove")
    //   var TOKEN = this.$storage.get("curAuthen").token
    //   var TID = this.$storage.get("curAuthen").tid
    //   axios({
    //     method: "get",
    //     url: "syslogin/" + TID,
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + TOKEN,
    //     },
    //   })
    //     .then((response) => {
    //       // this.tableData = response.data.data
    //       console.log(response.data.data)
    //       return
    //     })
    //     .catch((error) => console.log(error))
    // }

    return {
      menus,
      Logout,
      // isTokenRemove,
    }
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/")
      return routeArr[1]
    },
  },
  mounted() {
    // var a = this.getRoute()
    // console.log("a: ", a)
    //   const data = JSON.parse(localStorage.getItem("curAuthen"))
    //   if (data === null) {
    //     this.logout()
    //   } else {
    //     if (data.isAuthenticated != true || data == null) {
    //       this.logout()
    //     }
    //   }
  },
}
</script>
