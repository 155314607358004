export default {
  welcomeMessage: 'The Progressive JavaScript Framework',
  highlights: {
    approachable: 'Approachable'
  }, 
  english: 'EN',
  thai: 'TH',
  title: 'Hello {0}!',
  msg: 'You have {f} friends and {l} likes',
  notAutorized: 'Not Authorized to Access from Prototypes',
  confirmDeleteTitle: 'Confirm Delete!',
  confirmDelete: 'Delete! Are you sure you want to delete this data?',
  titleNotification: 'Your attention is required',
  usersTable: 'Users Table',
  search: 'Search',
  add: 'Add',
  edit: 'Edit',
  back: 'Back',
  user: 'User',
  no: 'No',
  firstName: 'First Name',
  lastName: 'Last Name',
  username: 'Username',
  status: 'Status',
  password: 'Password',
  save: 'Save',
  saveChange: 'Save change',
  cancel: 'Cancel',
  close: 'Close',
  yesConfirmDelete: 'Yes, confirm delete!',
  saveAlready: 'Success, saved data!',
  deleteAlready: 'Success, deleted data!',
  oopsError: 'Oops.. Something Went Wrong..',
  oopsPleaseSelectQuestion: 'Oops.. Please select questions at least 1.',
  toastedErrorType:'error',
  loginSuccess: 'Login Success!',
  email: 'Email',
  groupCode: 'Group Code',
  groupName: 'Group Name',
  companyCode: 'Company Code',
  companyName: 'Company Name',
  companyAddress: 'Company Address ',
  companyPhone: 'Phone Number ',
  remark: 'Remark',
  statusIMUsed: 'Can not delete data. Data already been used!',
  StatusForbiddenDuplicate: 'Can not saved data. They are dupplicate!',
  code: 'Code',
  nameTh: 'Name (Th)',
  nameEn: 'Name (En)',
  createDate: 'Create At',
  courseCategory: 'Course Category',
  course: 'Course',
  coursePath: 'Path',
  courseLevelID: 'Course Level',
  hour: 'Hours',
  minute: 'Minutes',
  orderNo: 'No.',
  statusUnauthorized: 'Username or Password not correct!',
  statusUnauthorizedUsername: 'Username is not correct!',
  statusUnauthorizedPassword: 'Password is not correct!',

  //menu
  systemMenu: 'System',
  companyMenu: 'Companies',
  userMenu: 'System User',
  authGroupMenu: 'Auth Group',
  masterCourseMenu: 'Master Courses',
  categoryMenu: 'Categories',
  categoryItemMenu: 'Category Items',
  pathMenu: 'Paths',
  pathItemMenu: 'Paths Items',
  levelMenu: 'Levels',
  courseMenu: 'Courses',

  examMasterMenu: 'Exam Master',
  examBankMenu: 'Exam Bank',
  examDifficultyLevelMenu: 'Exam Difficulty Level',
  examRelateQuestionMenu: 'Exam Relate Question',
  examQuestionMenu: 'Exam Question',
  examAnswerTypeMenu: 'Exam Answer Type',
  examAnswerChoiceMenu: 'Exam Answer Choice',
  examAnswerExplainMenu: 'Exam Answer Explain',

  examMenu: 'Exam',
  examCourseMenu: 'Exam Course',
  examCourseQuestionMenu: 'Exam Course Question',
  examTransactionMenu: 'Exam Transaction',
  examTransactionItemMenu: 'Exam Transaction Item',




  remarkTh: 'Remark (Th)',
  remarkEn: 'Remark (En)',
  questionTh: 'Question (Th)',
  questionEn: 'Question (En)',
  answer: 'Answer',
  answerTh: 'Answer (Th)',
  answerEn: 'Answer (En)',
  examBankTh: 'Exam Bank (Th)',
  examBankEn: 'Exam Bank (En)',
  btnConfirmImportExam: 'Confirm start import exam questions',
  examCourse: 'Exam Course',

  //dialog box message
  deleteTitle: 'Are you sure?',
  deleteText: "You won't be able to revert this!",
  deleteConfirmButtonText: "Yes, delete it!",
  startQuestionConfirmButtonText: "Yes่, start choose questions!",

  statusTitle: 'Are you sure?',
  statusText: "You want to confirm update status!",
  statusConfirmButtonText: "Yes, update status now!",


  //New July 2020
  //start routes
  Home: 'Home',
  MenuSetting: 'Setting',
  SystemUserTables: 'Table User System',
  FormSystemUsers: 'New User System',
  FormSystemUsersID: 'Update User System',
  PermissionUser1: 'Permission',
  PermissionUser2: 'Permission',
  PermissionUser3: 'Permission',
  Main: 'Main',
  PermissionGroup1: 'สิทธิ์การใช้งาน', //no english
  PermissionGroup2: 'สิทธิ์การใช้งาน',
  PermissionGroup3: 'สิทธิ์การใช้งาน',
  GroupTables: 'ข้อมูลสิทธิ์การเข้าระบบ',
  FormGroup: 'เพิ่มสิทธิ์การเข้าระบบ (กลุ่มผู้ใช้งาน)',
  FormGroupID: 'แก้ไขสิทธิ์การเข้าระบบ (กลุ่มผู้ใช้งาน)',
  GroupTablesMain: 'Group Permission',

  SystemPrefixTables: 'คำนำหน้าชื่อ',
  SystemPrefix: 'คำนำหน้าชื่อ',
  SystemGeneralInformation: 'ข้อมูลทั่วไประบบ',
  //end routes

  //start header page Add/Edit
  headerFormSystemUsers: 'New / Update User ',
  //end header page Add/Edit
}
