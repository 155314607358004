<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-6 my-2">
            <div class="card h-100">
              <div class="card-header d-flex">
                <h5 class="mb-0 text-capitalize text-primary">
                  แก้ไข Tag Status
                </h5>
              </div>
              <div class="card-body pt-0">
                <div class="col-12">
                  <div>
                    <h6>
                      {{ $t("ScanTagBarcode") }}
                      <span class="text-danger text-lg">*</span>
                    </h6>
                  </div>
                  <input
                    id="Barcode"
                    type="text"
                    class="form-control form-control-lg h4"
                    name="Barcode"
                    maxlength="50"
                    :disabled="isDisabledBarcode"
                    placeholder="eg. 123456789"
                    @keyup.enter="getTagCode"
                    v-model.trim="Barcode"
                  />
                </div>

                <div class="col-12" v-show="isShowStatusSelect">
                  <div>
                    <h6>
                      สถานะ Tag Barcode
                      <span class="text-danger text-lg">*</span>
                    </h6>
                  </div>
                  <div class="choices">
                    <select
                      id="choices-status-options"
                      class="form-control form-control-lg h5"
                      name="choices-status-options"
                      :disabled="isDisabledStatus"
                    ></select>
                  </div>
                </div>
                <!-- button -->
                <div class="button-row d-flex mt-4">
                  <argon-button
                    type="button"
                    color="light"
                    variant="gradient"
                    class="js-btn-prev"
                    @click="clearData"
                    >ล้างข้อมูล</argon-button
                  >
                  <argon-button
                    type="button"
                    color="danger"
                    size="md"
                    variant="gradient"
                    class="ms-auto"
                    v-if="isShowEditBtn"
                    @click="editData"
                    >แก้ไขข้อมูล</argon-button
                  >
                  <argon-button
                    type="button"
                    color="primary"
                    size="md"
                    variant="gradient"
                    class="ms-auto"
                    v-if="isShowEditSaveBtn"
                    @click="checkSaveEdit"
                    >บันทึกการแก้ไข</argon-button
                  >
                </div>

                <!-- Matched Success -->
                <div class="mb-0 my-4" v-if="isShowNotification">
                  <argon-alert
                    icon="ni ni-check-bold ni-md"
                    :dismissible="true"
                  >
                    <strong>Success!</strong> {{ msgHeader }}
                    <hr class="horizontal dark m-0 my-2" />
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      <span class="text-dark h6"
                        >สถานะใหม่: {{ $t(TagStatus) }}</span
                      >

                      <br />
                      <span class="text-dark h6"
                        >จาก สถานะเก่า : {{ $t(OldTagStatus) }}</span
                      >

                      <br />
                    </p>
                    <hr class="horizontal dark m-0 my-2" />
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      Tag Barcode: {{ BarcodeResult }}
                      <br />
                      จำนวน QR Code: {{ TotalQrCode }}
                      <br />
                      รหัสสินค้า: {{ ProductCode }}
                      <br />
                      ชื่อสินค้า: {{ ProductName }}
                      <br />
                      ชนิดถุง: {{ BagType }}
                      <br />
                      สถานะ Tag Barcode: {{ $t(TagStatus) }}
                      <br />
                      วันที่ทำรายการ: {{ UpdatedAt }}
                    </p>
                  </argon-alert>
                </div>
                <!-- QR Codde Detail -->
                <div v-if="isShowCodeDetail">
                  <div
                    class="d-flex align-items-center justify-content-center mb-0 bg-gray-100 border-radius-lg p-2 my-4"
                  >
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      จำนวน QR Code:
                    </p>
                    <p
                      class="ms-auto text-primary h3 font-weight-bold my-auto me-3"
                    >
                      {{ TotalQrCode }}
                    </p>
                  </div>
                  <div class="mb-0 bg-gray-100 border-radius-lg p-2 my-4">
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <p class="text-sm font-weight-bold my-auto ps-sm-2">
                        สถานะ Tag Barcode:
                      </p>
                      <p
                        class="ms-auto text-primary h6 font-weight-bold my-auto me-3"
                      >
                        {{ $t(TagStatus) }}
                      </p>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <p class="text-sm font-weight-bold my-auto ps-sm-2">
                        ชนิดถุง:
                      </p>
                      <p class="ms-auto text-sm font-weight-bold my-auto me-3">
                        {{ BagType }}
                      </p>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <p class="text-sm font-weight-bold my-auto ps-sm-2">
                        Lot Number:
                      </p>
                      <p
                        class="ms-auto text-primary h6 font-weight-bold my-auto me-3"
                      >
                        {{ LotNumber }}
                      </p>
                    </div>
                  </div>
                </div>

                <!-- Product Detail -->
                <div
                  v-if="isShowStatusSelect"
                  class="mb-0 bg-gray-100 border-radius-lg p-2 my-4"
                >
                  <div class="d-sm-flex align-items-center">
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      รายละเอียดสินค้า (Product):
                    </p>
                  </div>
                  <div class="d-flex align-items-center justify-content-center">
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      รหัส:
                    </p>
                    <p class="ms-auto text-sm font-weight-bold my-auto me-3">
                      {{ ProductCode }}
                    </p>
                  </div>
                  <div class="d-flex align-items-center justify-content-center">
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      ชื่อ:
                    </p>
                    <p
                      class="ms-auto text-primary h6 font-weight-bold my-auto me-3"
                    >
                      {{ ProductName }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- section how to use -->
          <div class="col-lg-6 my-2">
            <div class="card">
              <div
                class="p-3 mx-4 text-center card-header d-flex justify-content-center"
              >
                <div
                  class="icon icon-shape icon-md shadow text-center border-radius-lg bg-gradient-success"
                >
                  <i
                    class="opacity-10 ni ni-single-copy-04"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div class="p-3 pt-0 mx-2 card-body">
                <h6 class="mb-0 text-center">วิธีการใช้งาน</h6>
                <hr class="my-3 horizontal dark" />
                <div class="">
                  <ul class="list-unstyled">
                    <li class="h6">
                      สำหรับ Admin แก้ไขสถานะ Tag Barcode กรณีกดปุ่มผิด หรือ
                      ต้องการแก้ไขสถานะ
                    </li>
                    <li>1. ผู้ใช้งานสแกน Tag Barcode ที่ต้องการแก้ไขสถานะ</li>
                    <ul>
                      <li>จากนั้น ระบบจะแสดง ปุ่ม "แก้ไขข้อมูล"</li>
                      <ul>
                        <li>
                          สำหรับ Station 2: ปุ่ม "แก้ไขข้อมูล"
                          จะแสดงเมื่อสถานะของ Tag Barcode อยู่ในช่วงการทำงานของ
                          Station 2 เท่านั้น โดยที่ Station 3
                          ยังไม่ได้นำไปใช้งาน
                        </li>
                        <li>
                          สำหรับ Station 3: ปุ่ม "แก้ไขข้อมูล"
                          จะแสดงเมื่อสถานะของ Tag Barcode อยู่ในช่วงการทำงานของ
                          Station 3 เท่านั้น
                        </li>
                      </ul>
                      <li>และระบบจะแสดงข้อมูลสินค้า ที่กรอบด้านล่าง</li>
                    </ul>
                    <li>
                      2. กดปุ่ม "แก้ไขข้อมูล" และเลือกสถานะที่ต้องการแก้ไข
                      <ul>
                        <li>
                          หลังจากทำรายการเสร็จสิ้น Tag Barcode
                          จะสามารถนำกลับไปใช้งาน จับคู่ถุงนอกใหม่ได้อีกครั้ง
                        </li>
                      </ul>
                    </li>
                    <li>
                      3. กดปุ่ม "บันทึกการแก้ไข" เพื่อบันทึกข้อมูลสถานะที่แก้ไข
                    </li>
                    <li>4. กดปุ่ม "ล้างข้อมูล" เพื่อล้างข้อมูลที่กรอกไว้</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios"
import { ref, onMounted } from "vue"
import { useForm, useField } from "vee-validate"
import { useI18n } from "vue-i18n"
import { useRouter } from "vue-router"
import { object, string, lazy } from "yup"
import Swal from "sweetalert2"
import getDateFormat from "@/includes/getDateFormat"
// import getTodayDateFormat from "@/includes/getTodayDateFormat"

import ArgonLabel from "@/components/ArgonLabel.vue"
import ArgonButton from "@/components/ArgonButton.vue"
import ArgonAlert from "@/components/ArgonAlert.vue"

export default {
  name: "ChangeTagStatus",
  components: {
    ArgonButton,
    ArgonAlert,
    ArgonLabel,
  },
  data() {
    return {}
  },
  setup(props, ctx) {
    const { t } = useI18n({})
    const router = useRouter()

    var ProductCode = ref(null)
    var ProductName = ref(null)
    var TagStatus = ref(null)
    var CurrentTagStatus = ref(null)
    var OldTagStatus = ref(null)
    var TotalQrCode = ref(null)
    var BagType = ref(null)
    var UpdatedAt = ref(null)
    var msgHeader = ref(null)

    //show
    var isShowCodeDetail = ref(false)
    var isShowStatusSelect = ref(false)
    var isShowNotification = ref(false)
    var isShowAlertStatus = ref(false)
    var isDisabledBarcode = ref(false)

    var isShowEditBtn = ref(false)
    var isShowEditSaveBtn = ref(false)
    var isDisabledStatus = ref(true)

    var Barcode = ref(null)
    var BarcodeResult = ref(null)

    var SelectStatusCode = ref(null)
    var LotNumber = ref(null)

    const { handleSubmit } = useForm({
      validationSchema: schema,
    })

    function focusOnBarCode() {
      const el = document.getElementById("Barcode")
      el.focus()
    }

    function unFocusOnBarCode() {
      const el = document.getElementById("Barcode")
      el.blur()
    }

    function unAuthorized() {
      localStorage.removeItem("curAuthen")
      localStorage.removeItem("perms")
      router.push("/login")
    }

    var group = ""
    var statusList = []
    if (JSON.parse(localStorage.getItem("curAuthen"))) {
      group = JSON.parse(localStorage.getItem("curAuthen")).groups
      switch (group) {
        case "STA2":
          statusList = [
            { value: "Ready", text: t("Ready") },
            { value: "Mprostart", text: t("Mprostart") },
            { value: "Mprofinish", text: t("Mprofinish") },
          ]
          break
        case "STA3":
          statusList = [
            { value: "Mprofinish", text: t("Mprofinish") },
            { value: "Mlotno", text: t("Mlotno") },
            { value: "Activated", text: t("Activated") },
          ]
          break
        case "ALL":
          statusList = [
            { value: "Ready", text: t("Ready") },
            { value: "Mprostart", text: t("Mprostart") },
            { value: "Mprofinish", text: t("Mprofinish") },
            { value: "Mlotno", text: t("Mlotno") },
            { value: "Activated", text: t("Activated") },
          ]
          break
        default:
          statusList = [{ value: "NoData", text: "ไม่มีข้อมูล" }]
          break
      }
    } else {
      statusList = [{ value: "NoData", text: "ไม่มีข้อมูล" }]
    }

    onMounted(() => {
      focusOnBarCode()
      if (document.getElementById("choices-status-options")) {
        var element = document.getElementById("choices-status-options")
        var result = ""
        setTimeout(function () {
          if (statusList) {
            statusList.forEach((rs) => {
              result += `<option value="${rs.value}">${rs.text}</option>`
            })
            element.innerHTML = result
          }
        }, 100)
        element.addEventListener("change", (event) => {
          onChangeStatus(event.target.value)
        })
      }
    })

    function onChangeStatus(id) {
      SelectStatusCode.value = id
    }

    const schema = lazy(() =>
      object({
        Barcode: string()
          .required(() => t("errors.required", { field: t("Barcode") }))
          .min(18, t("errors.min", { length: 18 }))
          .max(50, t("errors.max", { length: 50 })),
      })
    )

    function checkSaveEdit() {
      isDisabledBarcode.value = true
      if (Barcode.value == null) {
        showAlertNoBarcode()
        return
      }
      if (Barcode.value == 0) {
        showAlertNoBarcode()
        return
      }
      if (SelectStatusCode.value == null) {
        showAlertStatus()
        return
      }
      if (SelectStatusCode.value == 0) {
        showAlertStatus()
        return
      }
      if (SelectStatusCode.value == CurrentTagStatus.value) {
        showAlertStatus()
        return
      }
      showAlertConfirm()
    }

    const saveEdit = handleSubmit((values) => {
      const TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
      const uid = JSON.parse(localStorage.getItem("curAuthen")).uid
      const objEdit = {
        TagCode: Barcode.value,
        NewTagStatus: SelectStatusCode.value,
        CurrentTagStatus: CurrentTagStatus.value,
        UpdatedBy: uid,
      }
      let res = axios({
        method: "put",
        url: "tagStatusChange/" + Barcode.value + "/" + CurrentTagStatus.value,
        data: objEdit,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
        .then((response) => {
          OldTagStatus.value = CurrentTagStatus.value
          clear()
          let rs = response.data.data
          BarcodeResult.value = rs.TagCode
          Barcode.value = rs.TagCode
          ProductName.value = rs.ProductName
          ProductCode.value = rs.ProductCode
          TagStatus.value = rs.TagStatus
          TotalQrCode.value = rs.TotalQrCode
          BagType.value = rs.BagType
          UpdatedAt.value = ""
          if (rs.UpdatedAt != null) {
            UpdatedAt.value = getDateFormat(rs.UpdatedAt)
          }
          CurrentTagStatus.value = rs.TagStatus
          isShowNotification.value = true
          isShowEditBtn.value = false
          isShowStatusSelect.value = false
          isShowEditSaveBtn.value = false
          unFocusOnBarCode()
          return true
        })
        .catch((error) => {
          if (error.response.status === 401) {
            unAuthorized()
            return
          }
          showAlertCannotSave()
          return false // return false to stop the form submission
        })
        .then((rs) => {
          return rs // for await purpose
        })
      return res
      //save
    }, onInvalidSubmit)

    async function getTagCode() {
      if (Barcode.value) {
        let TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
        let res = await axios({
          method: "get",
          url: "tagTransactionByTagCode/" + Barcode.value,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
          .then((response) => {
            let rs = response.data.data
            ProductCode.value = rs.ProductCode
            ProductName.value = rs.ProductName
            TagStatus.value = rs.TagStatus
            TotalQrCode.value = rs.TotalQrCode
            BagType.value = rs.BagType
            LotNumber.value = rs.LotNumber
            SelectStatusCode.value = rs.TagStatus
            CurrentTagStatus.value = rs.TagStatus
            document.getElementById("choices-status-options").value =
              TagStatus.value
            SelectStatusCode.value = TagStatus.value
            return rs
          })
          .catch((error) => {
            if (error.response.status === 401) {
              unAuthorized()
              return
            }
            Swal.fire(
              "Not Found!",
              "ไม่มีรหัส Barcode " + Barcode.value + " ในระบบ",
              ""
            )
            setTimeout(function () {
              isDisabledBarcode.value = false
              clear()
            }, 10)
            return false // pass to finish
          })
          .then((rs) => {
            isShowNotification.value = false
            isDisabledBarcode.value = true
            isShowEditBtn.value = false
            isShowStatusSelect.value = true
            isShowCodeDetail.value = true
            isDisabledStatus.value = true

            //check status available to change
            if (group == "STA2") {
              switch (rs.TagStatus) {
                case "Ready":
                  isShowEditBtn.value = true
                  break
                case "Mprostart":
                  isShowEditBtn.value = true
                  break
                case "Mprofinish":
                  isShowEditBtn.value = true
                  break
                case "Mlotno":
                  isShowEditBtn.value = true
                  break
                case "Activated":
                  isShowEditBtn.value = true
                  break
                default:
                  isShowEditBtn.value = false
                  break
              }
            } else if (group == "STA3") {
              switch (rs.TagStatus) {
                case "Mlotno":
                  isShowEditBtn.value = true
                  break
                case "Activated":
                  isShowEditBtn.value = true
                  break
                default:
                  isShowEditBtn.value = false
                  break
              } // I'm here
            } else if (group == "ALL") {
              switch (rs.TagStatus) {
                case "Ready":
                  isShowEditBtn.value = true
                  break
                case "Mprostart":
                  isShowEditBtn.value = true
                  break
                case "Mprofinish":
                  isShowEditBtn.value = true
                  break
                case "Mlotno":
                  isShowEditBtn.value = true
                  break
                case "Activated":
                  isShowEditBtn.value = true
                  break
                default:
                  isShowEditBtn.value = false
                  break
              }
            }

            return rs // for await purpose
          })
        return res
      } else {
        showAlertNoBarcode()
        isDisabledBarcode.value = false
      }
    }

    let isInvalidSubmit = false
    function onInvalidSubmit({ values, errors, results }) {
      isInvalidSubmit = true
    }

    function clearData() {
      clear()
      setTimeout(function () {
        focusOnBarCode()
      }, 1)
    }

    function clear() {
      isDisabledBarcode.value = false
      isShowCodeDetail.value = false
      isShowStatusSelect.value = false
      isShowAlertStatus.value = false
      isShowNotification.value = false
      isDisabledStatus.value = true
      isShowEditBtn.value = false
      isShowEditSaveBtn.value = false
      Barcode.value = ""
      focusOnBarCode()
    }

    function editData() {
      isShowEditSaveBtn.value = true
      isShowEditBtn.value = false
      isShowStatusSelect.value = true
      isDisabledStatus.value = false
      // isShowAlertBarcode.value = false
      isShowNotification.value = false
    }

    function showAlertConfirm() {
      Swal.fire({
        title: "ยืนยันบันทึกข้อมูล?",
        text:
          "แก้ไขสถานะของ Tag Barcode: " +
          Barcode.value +
          " เป็น " +
          t(SelectStatusCode.value),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน, บันทึกข้อมูล!",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          saveEdit()
        }
      })
    }

    function showAlertNoBarcode() {
      Swal.fire("กรุณากรอกข้อมูล Barcode")
    }

    function showAlertStatus() {
      Swal.fire("กรุณาเลือกสถานะ")
    }

    function showAlertCannotSave() {
      Swal.fire(
        "Error!",
        "ไม่สามารถบันทึกข้อมูลได้ กรุณาตรวจสอบ Barcode " +
          Barcode.value +
          " ในระบบ ว่ามีอยู่จริง!",
        ""
      )
    }

    return {
      t,
      ProductName,
      ProductCode,
      TagStatus,
      TotalQrCode,
      getTagCode,
      clear,
      isInvalidSubmit,
      isShowCodeDetail,
      isShowStatusSelect,
      isShowNotification,
      isShowAlertStatus,
      isDisabledBarcode,
      Barcode,
      BarcodeResult,
      BagType,
      UpdatedAt,
      clearData,
      msgHeader,
      SelectStatusCode,
      LotNumber,
      isShowEditBtn,
      isShowEditSaveBtn,
      isDisabledStatus,
      editData,
      checkSaveEdit,
      OldTagStatus,
    }
  },
}
</script>
