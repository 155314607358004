<template>
  <div class="container-fluid py-5">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form">
          <div class="row">
            <div class="col-12 col-lg-8 mx-auto mb-4">
              <div class="card">
                <div class="card-body">
                  <div class="multisteps-form__progress">
                    <button
                      class="multisteps-form__progress-btn js-active"
                      type="button"
                      title="User Info"
                      :class="activeStep >= 0 ? activeClass : ''"
                      @click="activeStep = 0"
                    >
                      <span>User Info</span>
                    </button>
                    <button
                      class="multisteps-form__progress-btn"
                      type="button"
                      title="Address"
                      :class="activeStep >= 1 ? activeClass : ''"
                      @click="activeStep = 1"
                    >
                      Address
                    </button>
                    <button
                      class="multisteps-form__progress-btn"
                      type="button"
                      title="Socials"
                      :class="activeStep >= 2 ? activeClass : ''"
                      @click="activeStep = 2"
                    >
                      Contacts
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--form panels-->
          <div class="row">
            <div class="col-12 col-lg-8 m-auto">
              <form class="multisteps-form__form">
                <!--single form panel-->
                <user-info :class="activeStep === 0 ? activeClass : ''" @nextStepAddress="nextStepAddress" />
                <!--single form panel-->
                <address-component :class="activeStep === 1 ? activeClass : ''" @nextStepContact="nextStepContact" />
                <!--single form panel-->
                <contact-info :class="activeStep === 2 ? activeClass : ''" @nextStepSend="nextStepSend" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import UserInfo from "./components/UserInfo.vue";
import AddressComponent from "./components/Address.vue";
import ContactInfo from "./components/Contact.vue";
// import { setLocale } from '@vee-validate/i18n';

export default {
  name: "NewUser",
  components: {
    UserInfo,
    AddressComponent,
    ContactInfo,
  },
  setup(props,ctx){
    var showMenu = ref(false);
    var activeClass= ref("js-active position-relative");
    var activeStep= ref(0);
    var formSteps= ref(3);

    const router = useRouter()
    function nextStep(params) {
      if (activeStep.value < formSteps.value) {
        activeStep.value += 1;
        console.log(activeStep.value);
      } else {
        activeStep.value -= 1;
        console.log(activeStep.value);
      }
    }
    function prevStep() {
      if (activeStep.value > 0) {
        activeStep.value -= 1;
        console.log(activeStep.value);
      // } else {
        // activeStep += 1;
        // console.log(activeStep);
      }
    }

    var address ={};
    var contact ={};
    var user ={};
    function nextStepAddress(params){
      nextStep();
      user = params;
    }

    function nextStepContact(params) {
      nextStep();
      address = params;
    }
    function nextStepSend(params) {
      nextStep();
      contact = params;
      saveData();
    }

    async function saveData(){
      console.log("saveData");
      console.log("contact=>",contact);
      console.log("address=>",address);
      console.log("user=>",user);
      let TOKEN = JSON.parse(localStorage.getItem('curAuthen')).token;
      var obj = {
        FirstName: user.FirstName,
        // LastName: user.LastName,
        // Company: user.Company,
        // Email: user.Email,
        // Password: user.Password,
      }


    }


    return {
      showMenu,
      activeClass,
      activeStep,
      formSteps,
      nextStep,
      prevStep,
      nextStepContact,
      nextStepSend,
      nextStepAddress,
    }
  },
  methods: {
    // nextStep() {
    //   if (this.activeStep < this.formSteps) {
    //     this.activeStep += 1;
    //     console.log(this.activeStep);
    //   } else {
    //     this.activeStep -= 1;
    //     console.log(this.activeStep);
    //   }
    // },
    // prevStep() {
    //   if (this.activeStep > 0) {
    //     this.activeStep -= 1;
    //     console.log(this.activeStep);
    //   }
    // },
  },
};
</script>
